import React, { useContext, useEffect, useState } from "react";
import { ProfileModel } from "../../../model/profile.model";
import "./input-search.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleInfo, faClose, faEdit, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, Checkbox, CircularProgress, Divider, FormControlLabel, ListSubheader, Popper, Radio, RadioGroup, Switch, TextField, Typography, autocompleteClasses, styled, useMediaQuery, useTheme } from "@mui/material";
import INPUT_JSON from "../../../assets/json/input-search.json"
import { Button, Form } from "react-bootstrap";
import ApiConfig from "../../../api-config";
import ApiServices from "../../../services/api.service";
import { SkillItem, SkillSuggestModel } from "../../../model/skills-suggest.model";
import PostSearchModel from "../../../model/post-search.model";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import SkillsModel from "../../../model/skills.model";
import { SigninContext } from "../../../context/signin.context";
import { Sort } from "../../../model/sort.model";
import ViewInputModal from "../../../modal/view-input.modal";
import { useWindowWidth } from "@react-hook/window-size";
import ResultModel from "../../../model/result.model";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { text } from "stream/consumers";
import config from "../../../config";
export interface InputSearchModel {
    name: string
    placeholder: string
    value: string
    error: string
    label: string
    is_required: boolean
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const InputSearchComponent = ({ inputSearchModel,
    startCheckResult, isEdit, setIsEdit, resultModel,
    setInputSearchModel, profileModel }: any) => {
    const domainArrayInternal = config.whitelist.internal;
    const [step, setStep] = useState(1);
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [resultMod, setResultMod] = useState<ResultModel>(resultModel);
    const [inputSearchMod, setInputSearchMod] = useState<PostSearchModel>();
    const { versionGlobal, setVersionGlobal }: any = useContext(SigninContext)
    const [versionMod, setVersionMod] = useState(versionGlobal);
    const [verifiedStep1, setVerifiedStep1] = useState(false);
    const { clienID }: any = useContext(SigninContext);
    const [formData, setFormData] = useState<InputSearchModel[]>(INPUT_JSON);
    const [skilLSuggestionList, setSkillSuggestionList] = useState<SkillSuggestModel>();
    const [inputValue, setInputValue] = useState<string[]>();
    const [allSkills, setAllSkills] = useState<SkillsModel>();
    const [searchSkills, setSearchSkills] = useState<string[]>(new Array);
    const [isLoadSkill, setIsLoadSkill] = useState(false);
    const [isEditMod, setIsEditMod] = useState(isEdit);
    const [isViewInput, setIsViewInput] = useState(false);
    const [sortValue, setSortValue] = useState<string>("EXP_AND_RELEVANT");
    const [sourceKey, setSourceKey] = useState<string>();
    const [filterValue, setFilterValue] = useState("exp");
    const [showInputPopup, setShowInputPopup] = useState(false);
    const [isInternalByDefault, setIsInternalByDefault] = useState(false);
    const width = useWindowWidth();
    const isMobile: Boolean = width < 768 ? true : false

    useEffect(() => {
        loadAllSkill();

    }, [])

    useEffect(() => {
        if (inputSearchModel) {
            if (inputSearchModel.sort == "EXP_AND_APPLIED_DATE") {
                setSortValue(Sort.EXP_AND_APPLIED_DATE);
            } else {
                setSortValue(Sort.EXP_AND_RELEVANT);
            }

            if (versionMod == ApiConfig.version.internal) {
                setSourceKey("internal");
            } else {
                setSourceKey("normal");
            }

        }
    }, [inputSearchModel])

    useEffect(() => {
        if (inputSearchMod) {

        } else {
            setInputSearchMod(inputSearchModel);
        }

    }, [inputSearchMod])


    useEffect(() => {

        if (resultModel) {
            setResultMod(resultModel);
        }
    }, [resultModel])
    useEffect(() => {

        if (versionGlobal) {
            setVersionMod(versionGlobal);
        }
    }, [versionGlobal])
    useEffect(() => {
        setIsEditMod(isEdit);

    }, [isEdit])

    useEffect(() => {
        if (profileModel) {
            checkInternalByDefault(profileModel);
        } 
    }, [profileModel])


    useEffect(() => {

        if (inputSearchMod) {
            const FormDateMod: InputSearchModel[] = new Array();

            setInputSearchMod(inputSearchModel)
            INPUT_JSON.map((item) => {
                if (item.name == "position") {
                    item.value = inputSearchModel.job_title;

                } else if (item.name == "jobDescription") {
                    item.value = inputSearchModel.job_description;
                } else if (item.name == "company") {
                    item.value = inputSearchModel.company;
                } else if (item.name == "industry") {
                    item.value = inputSearchModel.industry;
                } else if (item.name == "location") {
                    item.value = inputSearchModel.location;
                } else if (item.name == "keyword") {
                    item.value = inputSearchModel.keyword;
                } else if (item.name == "note") {
                    item.value = inputSearchModel.note;
                }
                FormDateMod.push(item);
            })
            setFormData(FormDateMod)
        }

    }, [inputSearchMod])

    const checkInternalByDefault = (profileMod) => {
        if(profileMod){
            domainArrayInternal.map((item: string, index: number) => {
                if (profileMod.email.includes(item)) {
                    setIsInternalByDefault(true);
                }
            })
        }
       
    }

    const clearAll = () => {
        const formDataMod = [...formData];
        formDataMod.map((item) => {
            item.value = "";
        })
        setFormData(formDataMod);
    }
    const onValueChange = (index, value) => {
        const formDataMod = [...formData];
        if (value.includes(",")) {
            const valuesReplace = value.replace(",", " ");
            formDataMod[index].value = valuesReplace;
            setFormData(formDataMod);
        } else {
            formDataMod[index].value = value;
            setFormData(formDataMod);
        }


    }

    const submitStep1 = (isQuickSearch?) => {
        setVerifiedStep1(true);
        const isPass = formData.filter(item => item.is_required && item.error.length == 0 && item.value.length == 0);
        if (isPass.length == 0) {
            setVerifiedStep1(false);
            const jobTitleValue = formData.filter(item => item.name == "position")[0];
            const jobDescription = formData.filter(item => item.name == "jobDescription")[0];
            if (jobTitleValue && inputSearchMod) {
                const inputSearchObj = { ...inputSearchMod };


                formData.map((item) => {
                    if (item.name == "position") {
                        inputSearchObj.job_title = item.value;
                    } else if (item.name == "jobDescription") {
                        inputSearchObj.job_description = item.value;
                    } else if (item.name == "company") {
                        inputSearchObj.company = item.value;
                    } else if (item.name == "industry") {
                        inputSearchObj.industry = item.value;
                    } else if (item.name == "location") {
                        inputSearchObj.location = item.value;
                    } else if (item.name == "keyword") {
                        inputSearchObj.keyword = item.value;
                    } else if (item.name == "note") {
                        inputSearchObj.note = item.value;
                    }
                })
                setInputSearchModel(inputSearchObj)
                setInputSearchMod(inputSearchObj)
                getSkillSuggestion(jobTitleValue.value, jobDescription.value)
                setStep(2);


                if (isQuickSearch) {
                    clickQuickSearch(inputSearchObj)
                }
            }

        }
    }

    const getSkillSuggestion = (jobTitleValue, jobDescriptionValue) => {
        setIsLoadSkill(true);
        ApiServices().postSuggestion(jobTitleValue, jobDescriptionValue).then((res) => {
            if (res && inputSearchMod) {
                const result = res;

                if (result.job_description_skills.length > 0) {

                    const skillJDMod: SkillItem[] = new Array();
                    result.job_title_skills.map((item) => {

                        result.job_description_skills.map((items) => {

                            if (item == items) {
                                skillJDMod.push({
                                    name: item + "",
                                    status: true
                                })
                            } else if (inputSearchMod.skills && inputSearchMod.skills.includes(item)) {
                                skillJDMod.push({
                                    name: item + "",
                                    status: true
                                })
                            } else {
                                skillJDMod.push({
                                    name: item + "",
                                    status: false
                                })
                            }

                        })
                    })
                    const skillJTMod: SkillItem[] = new Array();
                    result.job_title_skills.map((item) => {
                        skillJTMod.push({
                            name: item + "",
                            status: false
                        })

                    })
                    const skillSuggestMod: SkillSuggestModel = {
                        job_description_skills: skillJDMod,
                        job_title_skills: skillJTMod
                    }
                    setSkillSuggestionList(skillSuggestMod);
                } else {
                    const skillJTMod: SkillItem[] = new Array();
                    result.job_title_skills.map((item) => {

                        if (inputSearchMod && inputSearchMod.skills && inputSearchMod.skills.includes(item)) {
                            skillJTMod.push({
                                name: item + "",
                                status: true
                            })
                        } else {
                            skillJTMod.push({
                                name: item + "",
                                status: false
                            })
                        }


                    })

                    const skillSuggestMod: SkillSuggestModel = {
                        job_description_skills: [],
                        job_title_skills: skillJTMod
                    }
                    setSkillSuggestionList(skillSuggestMod);
                }
                setIsLoadSkill(false);

            }
        })
    }

    const onSelectValue = (e) => {
        const arrayValue = e as string[];
        if (arrayValue.length > 0) {
            setInputValue(arrayValue);
        } else {
            setInputValue(arrayValue);
            clickEmpty();
        }

    }

    const onCheckSkill = (value, action, index) => {
        if (skilLSuggestionList) {
            const skillSuggestMod = { ...skilLSuggestionList };

            if (action == "jobDescription") {
                if (value) {
                    skillSuggestMod.job_description_skills[index].status = false;
                } else {
                    skillSuggestMod.job_description_skills[index].status = true;
                }

            } else {
                if (value) {
                    skillSuggestMod.job_title_skills[index].status = false;
                } else {
                    skillSuggestMod.job_title_skills[index].status = true;
                }
            }
            setSkillSuggestionList(skillSuggestMod);
        }

    }

    const submitSkill = () => {
        if (skilLSuggestionList && inputSearchMod) {
            const allSkill: string[] = new Array();
            skilLSuggestionList.job_description_skills.filter(item => item.status).map((item) => {

                allSkill.push(item.name);
            })

            skilLSuggestionList.job_title_skills.filter(item => item.status).map((item) => {

                allSkill.push(item.name);
            })

            if (inputValue) {
                inputValue.map((item) => {
                    allSkill.push(item);
                })
            }

            const inputSearchObj = { ...inputSearchMod };
            inputSearchObj.skills = allSkill;
            setInputSearchModel(inputSearchObj)
            setInputSearchMod(inputSearchObj)
            startCheckResult(inputSearchObj, versionMod);
            setIsEditMod(false);

        }
    }

    const clickQuickSearch = (inputSearchObj) => {
        startCheckResult(inputSearchObj, versionMod);
    }
    const loadAllSkill = () => {

        ApiServices().getSkill().then((res: SkillsModel) => {

            setAllSkills(res);
            const searchSkillMod = new Array();
            for (let index = 0; index < 100; index++) {
                searchSkillMod.push(res.skills[index]);
            }
            setSearchSkills(searchSkillMod)

        })
    }

    const clickEmpty = () => {
        if (allSkills && allSkills.skills.length > 0) {
            const searchSkillMod = new Array();
            for (let index = 0; index < 100; index++) {
                searchSkillMod.push(allSkills.skills[index]);
            }
            setSearchSkills(searchSkillMod)
        }

    }
    const filterAllSkill = (textValue) => {
        const allSkillMod = { ...allSkills }
        if (allSkillMod.skills) {
            const resultSkill = allSkillMod.skills.filter(item => item.includes(textValue.target.value))
            const searchSkillMod = new Array();
            for (let index = 0; index < 100; index++) {
                searchSkillMod.push(resultSkill[index]);
            }
            setSearchSkills(searchSkillMod)
        }


    }


    const editInput = () => {
        setIsEditMod(true);
        setIsEdit(true);
        setStep(1);
        setShowInputPopup(false);
    }

    const clickViewInput = () => {
        if (showInputPopup) {
            setShowInputPopup(false);
        } else {
            setShowInputPopup(true);
        }

    }

    const onSourceKeyChange = (e) => {
        const value = e.target.value;
        if (inputSearchMod) {
            const inputSearchModEdit = { ...inputSearchMod };
            if (value == "internal") {
                setVersionMod(ApiConfig.version.internal);
                setVersionGlobal(ApiConfig.version.internal);
                startCheckResult(inputSearchModEdit, ApiConfig.version.internal)
                setSourceKey("internal")
            } else {
                setVersionMod(ApiConfig.version.preview);
                setVersionGlobal(ApiConfig.version.preview);
                startCheckResult(inputSearchModEdit, ApiConfig.version.preview)
                setSourceKey("normal")
            }

          
        }


    }

    const onFilterChange = (e) => {
        const value = e.target.value;

        if (inputSearchMod) {
            const inputSearchObj = { ...inputSearchMod };
            if (value == "exp") {
                if (sortValue == Sort.RELEVANT) {
                    inputSearchObj.sort = Sort.EXP_AND_RELEVANT
                    setFilterValue("exp")
                } else {
                    inputSearchObj.sort = Sort.EXP_AND_APPLIED_DATE
                    setFilterValue("exp")
                }

            } else if (value == "noexp") {
                if (sortValue == Sort.RELEVANT) {
                    inputSearchObj.sort = Sort.RELEVANT
                    setFilterValue("noexp")
                } else {
                    inputSearchObj.sort = Sort.APPLIED_DATE
                    setFilterValue("noexp")
                }
            } else if (value == Sort.EXP_AND_RELEVANT) {

                setSortValue(Sort.EXP_AND_RELEVANT)
                inputSearchObj.sort = Sort.EXP_AND_RELEVANT
            } else if (value == Sort.EXP_AND_APPLIED_DATE) {
                setSortValue(Sort.EXP_AND_APPLIED_DATE)
                inputSearchObj.sort = Sort.EXP_AND_APPLIED_DATE
            }
            setInputSearchMod(inputSearchObj)
            startCheckResult(inputSearchObj, versionMod);
        }

    }
    return (

        <>
            <div className="input-search-main">
                <div className={
                    !isEditMod ? "input-search-box-edit-control" :
                        "input-search-box-control"}>
                    <div className="input-search-control">
                        {
                            !isMobile &&
                            <>
                                <div className="input-search-header-control">
                                    <FontAwesomeIcon icon={faSearch} className="input-search-header-icon"></FontAwesomeIcon>
                                    <span className="input-search-header-title">Talent Search</span>
                                </div>
                                <span className="input-search-header-sub-title">ค้นหาผู้สมัครด้วยข้อมูลโปรไฟล์จากเรซูเม่</span>
                                <Divider className="input-search-divider"></Divider>
                            </>

                        }


                        {
                            isMobile && !isEditMod && resultMod && resultMod.resumes.length > 0 && !showInputPopup && <div className="input-search-view-header-control">
                                <span className="input-search-view-header-text-control" onClick={() => clickViewInput()}>คลิกเพื่อดูข้อมูลการค้นหา</span>
                            </div>
                        }

                        {
                            showInputPopup && inputSearchMod &&

                            <div>
                                <div className="input-search-result-control">
                                    <div className="input-search-input-edit-header-control">
                                        <span className="input-search-result-header">คุณกำลังค้นหาผู้สมัครตามข้อมูลด้านล่าง</span>
                                        <FontAwesomeIcon icon={faClose} className="input-search-input-edit-button-close-mobile" onClick={clickViewInput}></FontAwesomeIcon>
                                    </div>
                                    <Divider className="input-search-result-divider"></Divider>

                                    <div className="input-search-result-input-value-flex">
                                        {
                                            inputSearchMod.job_title &&
                                            <> <span className="input-search-result-title">Job title</span>
                                                <Button className="input-search-result-value">{inputSearchMod.job_title}</Button>
                                                <Divider className="input-search-result-divider"></Divider>
                                            </>

                                        }
                                        {
                                            inputSearchMod.company &&
                                            <> <span className="input-search-result-title">Company</span>
                                                <Button className="input-search-result-value">{inputSearchMod.company}</Button>
                                                <Divider className="input-search-result-divider"></Divider>
                                            </>

                                        }
                                        {
                                            inputSearchMod.industry &&
                                            <> <span className="input-search-result-title">Industry</span>
                                                <Button className="input-search-result-value">{inputSearchMod.industry}</Button>
                                                <Divider className="input-search-result-divider"></Divider>
                                            </>

                                        }
                                        {
                                            inputSearchMod.keyword &&
                                            <> <span className="input-search-result-title">Keyword</span>
                                                <Button className="input-search-result-value">{inputSearchMod.keyword}</Button>
                                                <Divider className="input-search-result-divider"></Divider>
                                            </>

                                        }
                                        {
                                            inputSearchMod.location &&
                                            <> <span className="input-search-result-title">Location</span>
                                                <Button className="input-search-result-value">{inputSearchMod.location}</Button>
                                                <Divider className="input-search-result-divider"></Divider>
                                            </>

                                        }
                                        {
                                            inputSearchMod.job_description &&
                                            <> <span className="input-search-result-title">Job description</span>
                                                <Button className="input-search-result-value">{inputSearchMod.job_description}</Button>
                                                <Divider className="input-search-result-divider"></Divider>
                                            </>

                                        }
                                        {
                                            inputSearchMod.skills && inputSearchMod.skills.length > 0 &&
                                            <> <span className="input-search-result-title">Skills</span>
                                                <Button className="input-search-result-value">{inputSearchMod.skills.map((item, index) => {

                                                    return (
                                                        inputSearchMod.skills && index == inputSearchMod.skills.length - 1 ?
                                                            <span>{item}</span> : <span>{item}, </span>
                                                    )
                                                })}</Button>
                                                <Divider className="input-search-result-divider"></Divider>
                                            </>

                                        }
                                        {/* {
                                            inputSearchMod.sort &&
                                            <> <span className="input-search-result-title">Sort</span>
                                                <Button className="input-search-result-value">{inputSearchMod.sort == Sort.APPLIED_DATE ? "อัปเดตล่าสุด" : "ที่เกี่ยวข้องมากสุด"}</Button>
                                                <Divider className="input-search-result-divider"></Divider>
                                            </>

                                        } */}

                                        {
                                            inputSearchMod.note && (versionMod == ApiConfig.version.be_8external || versionMod == ApiConfig.version.internal) &&
                                            <> <span className="input-search-result-title">Note</span>
                                                <Button className="input-search-result-value">{inputSearchMod.note}</Button>
                                                <Divider className="input-search-result-divider"></Divider>
                                            </>

                                        }
                                    </div>
                                    <span className="input-search-input-edit-button-mobile" onClick={editInput}>แก้ไข</span>
                                    <Button className="input-search-result-button-edit" onClick={editInput}>แก้ไขการค้นหา</Button>
                                </div>
                            </div>
                        }
                        {
                            !isEditMod && !isMobile && inputSearchMod &&


                            <div className="input-search-result-control">
                                <span className="input-search-result-header">คุณกำลังค้นหาผู้สมัครตามข้อมูลด้านล่าง</span>
                                <Divider className="input-search-result-divider"></Divider>
                                {
                                    inputSearchMod.job_title &&
                                    <> <span className="input-search-result-title">Job title</span>
                                        <Button className="input-search-result-value">{inputSearchMod.job_title}</Button>
                                        <Divider className="input-search-result-divider"></Divider>
                                    </>

                                }
                                {
                                    inputSearchMod.company &&
                                    <> <span className="input-search-result-title">Company</span>
                                        <Button className="input-search-result-value">{inputSearchMod.company}</Button>
                                        <Divider className="input-search-result-divider"></Divider>
                                    </>

                                }
                                {
                                    inputSearchMod.industry &&
                                    <> <span className="input-search-result-title">Industry</span>
                                        <Button className="input-search-result-value">{inputSearchMod.industry}</Button>
                                        <Divider className="input-search-result-divider"></Divider>
                                    </>

                                }
                                {
                                    inputSearchMod.keyword &&
                                    <> <span className="input-search-result-title">Keyword</span>
                                        <Button className="input-search-result-value">{inputSearchMod.keyword}</Button>
                                        <Divider className="input-search-result-divider"></Divider>
                                    </>

                                }
                                {
                                    inputSearchMod.location &&
                                    <> <span className="input-search-result-title">Location</span>
                                        <Button className="input-search-result-value">{inputSearchMod.location}</Button>
                                        <Divider className="input-search-result-divider"></Divider>
                                    </>

                                }
                                {
                                    inputSearchMod.job_description &&
                                    <> <span className="input-search-result-title">Job description</span>
                                        <Button className="input-search-result-value">{inputSearchMod.job_description}</Button>
                                        <Divider className="input-search-result-divider"></Divider>
                                    </>

                                }
                                {
                                    inputSearchMod.skills && inputSearchMod.skills.length > 0 &&
                                    <> <span className="input-search-result-title">Skills</span>
                                        <Button className="input-search-result-value">{inputSearchMod.skills.map((item, index) => {

                                            return (
                                                inputSearchMod.skills && index == inputSearchMod.skills.length - 1 ?
                                                    <span>{item}</span> : <span>{item}, </span>
                                            )
                                        })}</Button>
                                        <Divider className="input-search-result-divider"></Divider>
                                    </>

                                }
                                {/* {
                                    inputSearchMod.sort &&
                                    <> <span className="input-search-result-title">Sort</span>
                                        <Button className="input-search-result-value">{inputSearchMod.sort == Sort.APPLIED_DATE ? "อัปเดตล่าสุด" : "ที่เกี่ยวข้องมากสุด"}</Button>
                                        <Divider className="input-search-result-divider"></Divider>
                                    </>

                                } */}

                                {
                                    inputSearchMod.note && (versionMod == ApiConfig.version.be_8external || versionMod == ApiConfig.version.internal) &&
                                    <> <span className="input-search-result-title">Note</span>
                                        <Button className="input-search-result-value">{inputSearchMod.note}</Button>
                                        <Divider className="input-search-result-divider"></Divider>
                                    </>

                                }
                                <Button className="input-search-result-button-edit" onClick={editInput}>แก้ไขการค้นหา</Button>
                            </div>
                        }
                        {
                            !isEditMod && !isMobile && isInternalByDefault &&

                            <div>
                                <div className="input-search-result-control">
                                    <div className="input-search-input-edit-header-control">
                                        <span className="input-search-result-header">เลือก version</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        {sortValue &&
                                            <>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={sourceKey}
                                                    name="radio-buttons-group"
                                                    className="input-search-box-filter-control"
                                                    onChange={(e) => onSourceKeyChange(e)}
                                                >
                                                    <FormControlLabel value="internal" control={<Radio />} label="Internal" />
                                                    <FormControlLabel value="normal" control={<Radio />} label="Users ทั่วไป" />
                                                </RadioGroup>
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {!isEditMod && !isMobile &&
                            <div>
                                <div className="input-search-result-control">
                                    {/* <div className="input-search-input-edit-header-control">
                                        <span className="input-search-result-header">ตัวกรอง</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        {filterValue &&
                                            <>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue={filterValue}
                                                    name="radio-buttons-group"
                                                    className="input-search-box-filter-control"
                                                    onChange={(e) => onFilterChange(e)}
                                                >
                                                    <FormControlLabel value="exp" control={<Radio />} label="มีประสบการณ์ทำงาน" />
                                                    <FormControlLabel value="noexp" control={<Radio />} label="ไม่มีประสบการณ์ทำงาน" />
                                                </RadioGroup>
                                            </>

                                        }
                                    </div> */}
                                    <div className="input-search-input-edit-header-control">
                                        <span className="input-search-result-header">เรียงตาม</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        {sortValue &&
                                            <>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={sortValue}
                                                    name="radio-buttons-group"
                                                    className="input-search-box-filter-control"
                                                    onChange={(e) => onFilterChange(e)}
                                                >
                                                    <FormControlLabel value="EXP_AND_RELEVANT" control={<Radio />} label="ที่เกี่ยวข้องทั้งหมด" />
                                                    <FormControlLabel value="EXP_AND_APPLIED_DATE" control={<Radio />} label="อัปเดตล่าสุด" />
                                                </RadioGroup>
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            isEditMod &&

                            <>
                                <div className="input-search-border-number-control">
                                    <div className="input-search-border-number">
                                        <span className="input-search-border-number-text">1</span></div>
                                    <span className="input-search-border-number-title">กรอกข้อมูลที่ต้องการค้นหา</span>
                                    <FontAwesomeIcon icon={faEdit} className="input-search-border-icon-edit" onClick={() => setStep(1)}></FontAwesomeIcon>

                                </div>
                                {
                                    step == 1 &&
                                    <div className="input-search-item-control">
                                        {
                                            formData.map((item, index) => {

                                                return (
                                                    <div className="" key={index}>
                                                        {
                                                            item.name == "note" ?

                                                                (versionMod == ApiConfig.version.be_8external || versionMod == ApiConfig.version.internal)
                                                                &&
                                                                <>
                                                                    <Form.Group  >
                                                                        <span className="input-search-item-title">{item.label}</span>
                                                                        <Form.Control type="text"
                                                                            required={item.is_required ? true : false}
                                                                            onChange={(e) => onValueChange(index, e.target.value)}
                                                                            value={item.value}
                                                                            className="input-search-item-form"
                                                                            placeholder={item.placeholder} />
                                                                    </Form.Group>

                                                                    {
                                                                        (verifiedStep1 && item.is_required && !item.value)
                                                                        &&
                                                                        <span className="input-search-error-text">กรุณากรอก</span>
                                                                    }
                                                                </> :
                                                                <>
                                                                    <Form.Group  >
                                                                        <span className="input-search-item-title">{item.label}</span>
                                                                        <Form.Control type="text"
                                                                            required={item.is_required ? true : false}
                                                                            onChange={(e) => onValueChange(index, e.target.value)}
                                                                            value={item.value}
                                                                            className="input-search-item-form"
                                                                            placeholder={item.placeholder} />
                                                                    </Form.Group>

                                                                    {
                                                                        (verifiedStep1 && item.is_required && !item.value)
                                                                        &&
                                                                        <span className="input-search-error-text">กรุณากรอก</span>
                                                                    }
                                                                </>


                                                        }

                                                    </div>

                                                )
                                            })
                                        }
                                        <span className="input-search-clear-text" onClick={clearAll}>ล้างค่าทั้งหมด</span>
                                        <div className="input-search-button-control">
                                            <Button className="input-search-button-submit" onClick={() => submitStep1(false)}>ถัดไป</Button>
                                            {
                                                versionMod == ApiConfig.version.internal &&

                                                <Button className="input-search-button-submit-quick" onClick={() => submitStep1(true)}>ค้นหาทันที</Button>
                                            }
                                        </div>
                                    </div>
                                }
                                <Divider className="input-search-divider"></Divider>
                                <div className="input-search-border-number-control">
                                    <div className="input-search-border-number">
                                        <span className="input-search-border-number-text">2</span></div>
                                    <span className="input-search-border-number-title">ระบุทักษะที่ระบบแนะนำ</span>

                                </div>
                                {
                                    step == 2 &&
                                    <div className="input-search-item-control">
                                        {
                                            isLoadSkill &&
                                            <div className="input-search-step-2-loading-control">
                                                <CircularProgress className="input-search-step-2-loading-progress"></CircularProgress>
                                                <span className="input-search-step-2-loading-text">กำลังโหลดสกิล</span>
                                            </div>
                                        }
                                        {
                                            !isLoadSkill && skilLSuggestionList &&

                                            <div className="d-flex flex-column">

                                                <span className="input-search-step-2-suggest-text-control">
                                                    <FontAwesomeIcon icon={faCircleInfo} className="input-search-step-2-suggest-icon"></FontAwesomeIcon>
                                                    คลิก
                                                    <FontAwesomeIcon icon={faPlus} className="input-search-step-2-suggest-icon"></FontAwesomeIcon>
                                                    เพื่อเลือกค้นหาด้วยทักษะนั้นๆ หรือเลือกค้นหาทักษะด้วยตัวเอง</span>
                                                {
                                                    skilLSuggestionList && skilLSuggestionList.job_title_skills.length > 0
                                                    &&
                                                    <>
                                                        <span className="input-search-step-2-title">ทักษะแนะนำจากชื่อตำแหน่งงาน</span>
                                                        <div className="input-search-step-2-tag-list-control">
                                                            {
                                                                skilLSuggestionList.job_title_skills.map((item, index) => {

                                                                    return (

                                                                        <div className={
                                                                            item.status ? "input-search-step-2-tag-highlight" : "input-search-step-2-tag"
                                                                        } key={index}
                                                                            onClick={() => onCheckSkill(item.status, "jobTitle", index)}>
                                                                            <span className="input-search-step-2-text">{item.name}</span>
                                                                            <FontAwesomeIcon icon={item.status ? faCheck : faPlus} className="input-search-step-2-icon"></FontAwesomeIcon>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                }

                                                {
                                                    skilLSuggestionList && skilLSuggestionList.job_description_skills.length > 0 &&
                                                    <>
                                                        <span className="input-search-step-2-title">ทักษะแนะนำจากชื่อรายละเอียดงาน</span>
                                                        <div className="input-search-step-2-tag-list-control">
                                                            {
                                                                skilLSuggestionList.job_description_skills.map((item, index) => {

                                                                    return (

                                                                        <div className={
                                                                            item.status ? "input-search-step-2-tag-highlight" : "input-search-step-2-tag"
                                                                        } key={index} onClick={() => onCheckSkill(item.status, "jobDescription", index)}>
                                                                            <span className="input-search-step-2-text">{item.name}</span>
                                                                            <FontAwesomeIcon icon={item.status ? faCheck : faPlus} className="input-search-step-2-icon"></FontAwesomeIcon>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </>
                                                }

                                                {/* {
                                                    allSkills &&
                                                    <Autocomplete
                                                        multiple
                                                        id="virtualize-demo"
                                                        className="input-search-step-2-other-skill"
                                                        options={allSkills.skills}
                                                        onChange={(event, values) =>
                                                            setInputValue(values)
                                                        }
                                                        value={inputValue}
                                                        defaultValue={inputValue}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                label="Multiple values"
                                                                placeholder="เลือกทักษะเพิ่มเติ่ม"
                                                            />
                                                        )}
                                                    />
                                                } */}
                                                {
                                                    searchSkills && searchSkills.length > 0 &&
                                                    <Autocomplete
                                                        multiple
                                                        className="input-search-step-2-other-skill"
                                                        options={searchSkills}
                                                        onChange={(event, values) =>
                                                            onSelectValue(values)
                                                        }
                                                        onInputChange={(e) => filterAllSkill(e)}
                                                        onEmptied={clickEmpty}
                                                        value={inputValue}
                                                        getOptionLabel={(option) => option}
                                                        defaultValue={inputValue}
                                                        renderInput={(params) => <TextField {...params} label="ทักษะอื่นๆ" />}
                                                    />
                                                }
                                                <Button className="input-search-button-submit" onClick={submitSkill}>เริ่มค้นหา</Button>
                                            </div>
                                        }

                                    </div>
                                }
                            </>
                        }





                    </div>
                </div>
                {/* {
                    showInputPopup &&
                    <ViewInputModal open={showInputPopup} handleClose={handleCloseInputPopup} inputModel={inputSearchMod} editInput={editInput}></ViewInputModal>
                } */}
            </div>
        </>
    )
}

export default InputSearchComponent;

