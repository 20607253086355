import { OauthModel } from "../model/auth/oauth.model";
import ProfileMysa from "../model/profile-mysa.model";
import storage from "../storage";

class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      return user?.refresh_token;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      return user?.access_token;
    }
    setLocalAccessToken(access_token){
      localStorage.setItem(storage.access_token,access_token);
      let user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      if(user){
        user.access_token = access_token;
        localStorage.setItem(storage.user, JSON.stringify(user));
      }
    
    }
    getUpdateLocalAccessToken(){
      const accessToken = localStorage.getItem(storage.access_token)!;
      return accessToken;
    }
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
      user.access_token = token;
      localStorage.setItem(storage.user, JSON.stringify(user));
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem(storage.user)!) as OauthModel;
    }
    setIsHr9Clinic(isHr9) {
      localStorage.setItem(storage.hr9Clinic, isHr9);
    }
    isHr9Clinic() {
      return localStorage.getItem(storage.hr9Clinic);
    }
    setUser(user) {
      localStorage.setItem(storage.user, JSON.stringify(user));
    }
    setCode(code) {
      localStorage.setItem(storage.code, code);
    }
    setVerifyCode(code) {
      localStorage.setItem(storage.code_verifier, code);
    }
    getVerifyCode() {
      return localStorage.getItem(storage.code_verifier);
    }
    getCode() {
      return localStorage.getItem(storage.code);
    }
    removeUser() {
      localStorage.removeItem(storage.user);
    }
    setProfile(profile){
      localStorage.setItem(storage.profile_mysa, JSON.stringify(profile));
    }
    getProfile() {
      return JSON.parse(localStorage.getItem(storage.profile_mysa)!) as ProfileMysa;
    }
  }
  
  export default new TokenService();