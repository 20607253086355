import {
    faArrowUpRightFromSquare, faBahtSign, faBirthdayCake, faBriefcase,
    faBusinessTime, faCakeCandles, faCheck, faClockRotateLeft,
    faClose, faEnvelope, faFile, faIdCard, faLightbulb, faLocationDot,
    faLock, faLockOpen, faMailBulk, faMoneyBill, faPhone, faUser, faUserClock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react"
import { Form, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import config from "../../../config";
import { Resume } from "../../../model/result.model";
import NOTED_ICON from "../../../assets/images/noted.png";
import { SigninContext } from "../../../context/signin.context";
import HistoryApplyModal from "../../../modal/history-apply.modal";
import ApiServices from "../../../services/api.service";
import NOTE_ICON from "../../../assets/images/note.png";
import { NoteResultModel } from "../../../model/note.model";
import ActivityLogModal from "../../../modal/activity-log.modal";
import HistoryJobsModal from "../../../modal/history-jobs.modal";
import GAServices from "../../../utils/ga-services";
import GAConfig from "../../../ga-config";
import ApiConfig from "../../../api-config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Button, Snackbar } from "@mui/material";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarActive } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarDefault } from "@fortawesome/free-regular-svg-icons";
import OpenCandidateModel from "../../../model/open-candidate.model";
const ResumeListComponent = ({ resumeModelPass,
    isShowImportCandidate, onSelectImport, versionModel,
    isImportMode, selectCandidateImport, clickInteresCandidate,
    saveFavorite, removeFavorite, sourceKey, keywordHightlight }: any) => {
    const [resumeModel, setResumeModel] = useState<Resume[]>([]);
    const [versionMod, setVersionMod] = useState(versionModel)
    const [currentResume, setCurrentResume] = useState<Resume>();
    const [showHistoryApply, setShowHistoryApply] = useState(false);
    const [showHistoryJobs, setShowHistoryJobs] = useState(false);
    const [userName, setUserName] = useState("");
    const { profileModelContext, uuidPassGlobal, clientID, versionGlobal }: any = useContext(SigninContext);
    const [isShowLogs, setIsShowLogs] = useState(false);
    const [uuidPass, setUuidPass] = useState("");
    const navigate = useNavigate();
    const [isModalSnack, setIsModalSnack] = useState(false);
    const [openProfileImg, setOpenProfileImg] = useState(false);
    const [profileUrlOpen, setProfileUrlOpen] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [keywordHightlightMod, setKeywordHightlightMod] = useState<string[]>(keywordHightlight)
    const [openCandidateModel, setOpenCandidateModel] = useState<OpenCandidateModel>();

    useEffect(() => {

        if (resumeModel) {
            console.log(resumeModel);
        }
    }, [resumeModel])

    useEffect(() => {
        if (resumeModelPass) {
            setResumeModel(resumeModelPass);
        }
    }, [resumeModelPass])
    useEffect(() => {

        if (versionGlobal) {
            setVersionMod(versionGlobal);
        }
    }, [versionGlobal])


    useEffect(() => {


        if (profileModelContext) {
            setUserName(profileModelContext.email)
        }
    }, [profileModelContext])

    useEffect(() => {
        if (searchParams.get("uuid")) {
            setUuidPass(searchParams.get("uuid") + "")
        }
    }, [])






    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age + " ปี";
    }

    const clickHistoryApply = (resume: Resume) => {
        setShowHistoryApply(true)
        setCurrentResume(resume);
        GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.show_history_job_apply }, versionMod)
    }
    const clickHistoryJobs = (resume: Resume) => {
        setShowHistoryJobs(true)
        setCurrentResume(resume);
        GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.show_history_job_experience }, versionMod)
    }
    const clickActivityLogs = (resume: Resume) => {
        setIsShowLogs(true)
        setCurrentResume(resume);
        GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.show_activity_log }, versionMod)
    }


    const handleCloseLogs = () => {
        if (isShowLogs) {
            setIsShowLogs(false);
        } else {
            setIsShowLogs(true);
        }
    }
    const handleCloseHistoryApplyModal = () => {
        if (showHistoryApply) {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.close_history_apply }, versionMod)
            setShowHistoryApply(false);
        } else {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.open_history_apply }, versionMod)
            setShowHistoryApply(true);
        }
    }

    const handleCloseHistoryJobsModal = () => {
        if (showHistoryJobs) {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.close_history_experience }, versionMod)
            setShowHistoryJobs(false);
        } else {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.open_history_experience }, versionMod)
            setShowHistoryJobs(true);
        }
    }
    const calculateExp = (date1, date2, index) => {
        const start = new Date(date1);
        const end = new Date(date2);
        const diffTime = Math.abs(Number(end) - Number(start));

        const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
        const diffMonths = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
        if (diffYears == 0 && diffMonths > 0) {
            return diffMonths + " เดือน";
        } else if (diffYears == 0 && diffMonths == 0) {
            return "ไม่มีประสบการณ์ทำงาน";
        } else if (diffYears > 0 && diffMonths == 0) {
            return diffYears + " ปี ";
        }
        else {
            return diffYears + " ปี " + diffMonths + " เดือน";
        }

    }

    const clickDownloadResume = (item: Resume) => {
        let user_name = "";
        if (profileModelContext) {
            user_name = profileModelContext.email
        }

        ApiServices().downloadResume(item.bucket, item.key, user_name, item.candidate_id).then((res) => {
            if (res) {
                GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.download_resume, param_value: item.candidate_id }, versionMod)
                window.open(res.resume_url, '_blank');
            }

        })
    }
    const clickNote = (index) => {
        if (resumeModel) {
            const resumeMod = [...resumeModel];
            if (resumeMod[index].note_show) {
                GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.hide_note, param_value: resumeMod[index].candidate_id }, versionMod)
                resumeMod[index].note_show = false;
            } else {
                GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.show_note, param_value: resumeMod[index].candidate_id }, versionMod)
                resumeMod[index].note_show = true;
                resumeMod[index].preview_note = resumeMod[index].note;
            }
            setResumeModel(resumeMod);
        }

    }

    const cancelNote = (index) => {
        if (resumeModel) {
            const resumeMod = [...resumeModel];
            if (resumeMod[index].note_show) {
                GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.cancel_note, param_value: resumeMod[index].candidate_id }, versionMod)
                resumeMod[index].note_show = false;
            } else {
                GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.show_note, param_value: resumeMod[index].candidate_id }, versionMod)
                resumeMod[index].note_show = true;
            }
            setResumeModel(resumeMod);
        }

    }
    const viewAll = (index) => {
        if (resumeModel) {
            const resumeMod = [...resumeModel];
            if (resumeMod[index].note_viewall) {
                GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.hide_view_more_note, param_value: resumeMod[index].candidate_id }, versionMod)
                resumeMod[index].note_viewall = false;
            } else {
                GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.view_more_note, param_value: resumeMod[index].candidate_id }, versionMod)
                resumeMod[index].note_viewall = true;
            }

            setResumeModel(resumeMod);
        }

    }
    const addNote = (resume: Resume, index) => {
        if (profileModelContext && resumeModel) {
            ApiServices().addNote(resume.candidate_id, resume.preview_note, profileModelContext.email, sourceKey).then((res: NoteResultModel) => {
                const resumeMod = [...resumeModel];
                resumeMod[index].note_show = false
                resumeMod[index].note = res.note;
                resumeMod[index].note_updated_by = res.note_updated_by;
                resumeMod[index].note_updated_at = res.note_updated_at;
                setResumeModel(resumeMod);
                GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.add_note_success, param_value: res.note }, versionMod)
            })
        }

    }
    const onTextAreaChange = (e: any, index: number) => {
        if (resumeModel) {
            const resumeMod = [...resumeModel];
            resumeMod[index].preview_note = e.target.value
            setResumeModel(resumeMod);
        }
    }

    const sendCandidateToUseCredit = (id) => {
        const objecResult = {
            candidate_id: id,
            user_uuid: uuidPass
        }
        if (clientID == "TALENTSAUCE") {
            window.parent.postMessage(objecResult, config.url_talent_sauce);
        }else if(clientID == "TALENTPOOL"){
            window.parent.postMessage(objecResult, config.url_talent_sauce);
        }else if(clientID == "REERACOEN"){
            window.parent.postMessage(objecResult, config.url_talent_sauce);
        }else if(clientID == "SCOUTOUT"){
            window.parent.postMessage(objecResult, config.url_talent_sauce);
        }
        else {
            window.parent.postMessage(objecResult, config.url_hr9_clinic);
        }

    }

    useEffect(() => {

        window.addEventListener('message', (event) => {
            // Optionally check event.origin here for security
            const data = event.data as OpenCandidateModel;
            if (data.candidate_id) {
                console.log(data);
                setOpenCandidateModel(data);
            }
            // Handle the received status data
        });
    }, [])

    useEffect(() => {
        if (openCandidateModel) {
            console.log(openCandidateModel);
            if (resumeModel) {
                const resumeMod = [...resumeModel];
                const updatedUsers = resumeMod.map(user =>
                    user.candidate_id === openCandidateModel.candidate_id ? { ...user, is_open: true } : user
                );
                console.log(updatedUsers);
                setResumeModel(updatedUsers);
            }

        }
    }, [openCandidateModel])
    const handleCopyToClipboard = (id) => {
        const plainText = id;
        console.log(plainText);
        navigator.clipboard.writeText(plainText + "");
        setIsModalSnack(true);
    };

    const handleCloseSnack = () => {
        setIsModalSnack(false);
    }

    const clickViewCandidate = (resume) => {

        const params = new URLSearchParams({
            resultObject: JSON.stringify(resume),
            highlight: keywordHightlight,
            email: userName,
            client_id: clientID
        })
        window.open('/candidate-view?' + params, "_blank")
    }

    const clickViewImgProfile = (url) => {

        setOpenProfileImg(true);
        setProfileUrlOpen(url);
    }
    const convertHtmlString = (value) => {
        if (value) {
            let valueExp = "";
            if (value.length > 300) {
                valueExp = value as string;

            } else {
                valueExp = value as string;
            }
            const formattedText = valueExp.replace(/\n/g, '<br>');
            const formattedText2 = formattedText.replace(/•/g, '<li>').replace(/\n/g, '<br>');
            const formattedText3 = formattedText2.replace(/-/g, '<li>').replace(/\n/g, '<br>');
            const formattedText4 = formattedText3.replace("\n", '<br>');

            if (keywordHightlightMod) {
                return highlightKeywords(formattedText4, keywordHightlightMod)
            } else {
                return formattedText4;
            }

        } else {
            if (keywordHightlightMod) {

                return highlightKeywords(value, keywordHightlightMod)
            } else {
                return value;
            }

        }

    }
    function highlightKeywords(description: string, keywords: string[]): string {
        let highlightedDescription = description;

        try{
            keywords.forEach(keyword => {
                const regex = new RegExp(`(${keyword})`, 'gi');
                highlightedDescription = highlightedDescription.replace(regex, `<span style='background-color: yellow; font-weight:bold; color: black;'>$1</span>`);
            });
    
            return highlightedDescription;
        }catch{
            return description;
        }
    }
    const initValueCard = (item: Resume, index) => {

        return (
            <div className={
                item.is_import ? "box-candidate-control box-candidate-control-selected" :

                    item.is_open ? "box-candidate-control box-candidate-control-selected" : "box-candidate-control"
            } key={index}>
                <div className="box-candidate-content-control">
                    {
                        isShowImportCandidate && <div className="box-candidate-import-select-control" >
                            <Form.Check checked={item.is_import}
                                type='checkbox' className="box-candidate-import-select" onChange={(e) => onSelectImport(e, item)}
                            />
                            <span className="box-candidate-import-text">เลือก Resume นี้</span>
                        </div>
                    }



                    <div className="box-candidate-head-control">
                        {
                            versionMod != ApiConfig.version.preview &&
                            <FontAwesomeIcon icon={item.is_bookmark ? faStarActive : faStarDefault}
                                className="box-candidate-new-tab-icon"
                                onClick={item.is_bookmark ? () => removeFavorite(item) : () => saveFavorite(item)} />
                        }

                        <div className="d-flex flex-column">

                            {
                                versionMod === ApiConfig.version.internal && (item.profile_image_url) &&
                                <div className="box-candidate-job-title-control">
                                    <img src={item.profile_image_url} className="box-candidate-job-profile-img"
                                        onClick={() => clickViewImgProfile(item.profile_image_url)}></img>
                                </div>
                            }
                            <div className="box-candidate-job-title-control">
                                <FontAwesomeIcon icon={faIdCard} className="box-candidate-job-title-icon" />
                                <span className="box-candidate-job-company-bold">ID ผู้สมัคร:
                                    <span className="box-candidate-job-ID" onClick={() => clickViewCandidate(item)}>{item.candidate_id}</span>
                                    <FontAwesomeIcon icon={faCopy}
                                        onClick={() => handleCopyToClipboard(item.candidate_id)}
                                        className="box-candidate-job-company-copy"></FontAwesomeIcon> |
                                    <span className="box-candidate-experience-link"
                                        onClick={() => clickViewCandidate(item)}>ดูรายละเอียดผู้สมัคร</span>
                                </span>
                            </div>
                            {
                                versionMod == ApiConfig.version.internal && (item.first_name && item.last_name) &&
                                <div className="box-candidate-job-title-control">
                                    <FontAwesomeIcon icon={faUser} className="box-candidate-job-title-icon" />
                                    <span className="box-candidate-job-company-big">{item.first_name} {item.last_name}</span>
                                </div>
                            }

                            {
                                (item.work_experience_job_title) ?
                                    <div className="box-candidate-job-title-control">
                                        <FontAwesomeIcon icon={faBriefcase} className="box-candidate-job-title-icon" />
                                        <div className="box-candidate-job-company-control">
                                            <span className="box-candidate-job-company">ตำแหน่ง: </span>
                                            <div className="box-candidate-job-company-big"
                                            dangerouslySetInnerHTML={{__html: highlightKeywords(item.work_experience_job_title,keywordHightlightMod)}}>
                                               </div>
                                        </div>
                                    </div> :
                                    <div className="box-candidate-job-title-control">
                                        <FontAwesomeIcon icon={faBriefcase} className="box-candidate-job-title-icon" />
                                        <span className="box-candidate-job-title-null">-</span>


                                    </div>
                            }
                            {
                                (item.work_experience_company_name) ?
                                    <div className="box-candidate-job-title-control">
                                        <FontAwesomeIcon icon={faLocationDot} className="box-candidate-job-title-icon" />
                                        <div className="box-candidate-job-company-control">
                                            <span className="box-candidate-job-company">บริษัท: </span>
                                            <span className="box-candidate-job-company-big">{item.work_experience_company_name}</span>
                                        </div>
                                    </div> :
                                    <div className="box-candidate-job-title-control">
                                        <FontAwesomeIcon icon={faLocationDot} className="box-candidate-job-title-icon" />
                                        <div className="box-candidate-job-company-control">
                                            <span className="box-candidate-job-company">บริษัท: </span>
                                            <span className="box-candidate-job-company-big">-</span>
                                        </div>
                                    </div>
                            }
                            <span className="box-candidate-experience-text">
                                {
                                    item.work_experience_start_date || item.work_experience_end_date ?
                                        <div className="box-candidate-job-title-control">
                                            <FontAwesomeIcon icon={faBusinessTime} className="box-candidate-job-title-icon" />
                                            <span className="box-candidate-job-company">อายุงานล่าสุด:
                                                <span className="box-candidate-job-exp">
                                                    {
                                                        item.work_experience_still_work_here && item.work_experience_start_date ?
                                                            calculateExp(item.work_experience_start_date, new Date(), index).toString() :
                                                            item.work_experience_start_date && item.work_experience_end_date ?
                                                                calculateExp(item.work_experience_start_date, item.work_experience_end_date, index).toString() :
                                                                "ไม่มีประสบการณ์ทำงาน"

                                                    }</span>
                                                {
                                                    item.work_experience_still_work_here &&
                                                        calculateExp(item.work_experience_start_date, new Date(), index) != "ไม่มีประสบการณ์ทำงาน" ?
                                                        <> |<span className="box-candidate-experience-link"
                                                            onClick={() => clickHistoryJobs(item)}>ดูประวัติการทำงานทั้งหมด</span></>
                                                        :
                                                        !item.work_experience_still_work_here && calculateExp(item.work_experience_start_date, item.work_experience_end_date, index) != "ไม่มีประสบการณ์ทำงาน"
                                                            ?
                                                            <> |<span className="box-candidate-experience-link"
                                                                onClick={() => clickHistoryJobs(item)}>ดูประวัติการทำงานทั้งหมด</span></>
                                                            : null

                                                }
                                            </span>


                                        </div> :
                                        <div className="box-candidate-job-title-control">
                                            <FontAwesomeIcon icon={faBusinessTime} className="box-candidate-job-title-icon" />
                                            <span className="box-candidate-job-company">อายุงานล่าสุด:
                                                <span className="box-candidate-job-exp">-</span>
                                            </span>


                                        </div>
                                }

                                {
                                    versionMod == ApiConfig.version.internal &&
                                    <>
                                        <div className="box-candidate-job-title-control">
                                            <FontAwesomeIcon icon={faBusinessTime} className="box-candidate-job-title-icon" />
                                            <span className="box-candidate-job-company">ประวัติอื่นๆ:
                                                <span className="box-candidate-experience-link"
                                                    onClick={() => clickHistoryApply(item)}>ดูประวัติการสมัครงาน</span></span>


                                        </div>
                                    </>
                                }
                            </span>



                            {
                                versionMod == ApiConfig.version.internal && (item.work_experience_salary) &&
                                <div className="box-candidate-job-title-control">
                                    <FontAwesomeIcon icon={faMoneyBill} className="box-candidate-job-title-icon" />
                                    <span className="box-candidate-job-company">เงินเดือนล่าสุด:
                                        <span className="box-candidate-job-company-big">{Number(item.work_experience_salary).toLocaleString()}</span> บาท
                                    </span>
                                </div>
                            }
                            {
                                versionMod == ApiConfig.version.internal && (item.email) &&
                                <div className="box-candidate-job-title-control">
                                    <FontAwesomeIcon icon={faMailBulk} className="box-candidate-job-title-icon" />
                                    <span className="box-candidate-job-company">อีเมล:
                                        <span className="box-candidate-job-company-big">{item.email}</span>
                                    </span>
                                </div>
                            }
                            {
                                versionMod == ApiConfig.version.internal && (item.phone) &&
                                <div className="box-candidate-job-title-control">
                                    <FontAwesomeIcon icon={faPhone} className="box-candidate-job-title-icon" />
                                    <span className="box-candidate-job-company">เบอร์ติดต่อ:
                                        <span className="box-candidate-job-company-big">{item.phone}</span>
                                    </span>
                                </div>
                            }
                            {
                                versionMod == ApiConfig.version.internal && (item.date_of_birth) &&
                                <div className="box-candidate-job-title-control">
                                    <FontAwesomeIcon icon={faBirthdayCake} className="box-candidate-job-title-icon" />
                                    <span className="box-candidate-job-company">อายุ:
                                        <span className="box-candidate-job-company-big">{getAge(item.date_of_birth)}</span>
                                    </span>
                                </div>
                            }
                            {
                                versionMod == ApiConfig.version.internal && (item.key) &&
                                <div className="box-candidate-job-title-control">
                                    <FontAwesomeIcon icon={faFile} className="box-candidate-job-title-icon" />
                                    <span className="box-candidate-job-company">resume ผู้สมัคร:
                                        <span className="box-candidate-activity-link" onClick={() => clickDownloadResume(item)}>ดาวน์โหลด</span>
                                    </span>
                                </div>
                            }

                        </div>

                    </div>
                    {
                        item.work_experience_responsibilities != null &&
                        <>
                            <div className="line-box-content-divier"></div>
                            <div className="d-flex flex-column">
                                <div className="box-candidate-job-title-control">
                                    <FontAwesomeIcon icon={faBriefcase} className="box-candidate-job-title-icon" />
                                    <span className="box-candidate-job-company">หน้าที่ความรับผิดชอบล่าสุด:</span>
                                </div>
                                <span className="result-resume-responsibilt-text"
                                    dangerouslySetInnerHTML={{ __html: convertHtmlString(item.work_experience_responsibilities) }}>

                                </span>
                                <span className="result-resume-responsibilt-link" onClick={() => clickHistoryJobs(item)}>ดูประวัติการทำงานทั้งหมด</span>


                            </div>
                        </>
                    }


                    <div className="line-box-content-divier"></div>
                    {
                        item.match_skills && item.match_skills.length > 0 &&
                        <div className="skill-tag-candidate-main">
                            <div className="skill-tag-candidate-control">
                                <FontAwesomeIcon icon={faStarActive} className="skill-candidate-text-regular-icon" />
                                <span className="skill-candidate-text-regular">ทักษะที่ตรงกับที่คุณค้นหา :</span></div>
                            <div className="box-candidate-skills-tag-control">
                                {
                                    item.match_skills.map((items, indexs) => {

                                        return (
                                            <div className="box-candidate-skills-tag-item-control-highlight" key={indexs}>
                                                <div className="box-candidate-skills-tag-highlight" dangerouslySetInnerHTML={{__html:   highlightKeywords(items, keywordHightlightMod)}} >
                                                  
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                            </div>

                        </div>
                    }

                    {
                        item.skills &&
                        <div className="skill-tag-candidate-main">
                            <div className="skill-tag-candidate-control">
                                <FontAwesomeIcon icon={faLightbulb} className="skill-candidate-text-regular-icon" />
                                <span className="skill-candidate-text-regular">ทักษะอื่นๆที่โดดเด่นของผู้สมัคร :</span></div>
                            {
                                item.skills.length == 0 ?

                                    <div className="box-candidate-skills-no-result">
                                        <div className="box-candidate-skills-no-result-control">
                                            <span className="box-candidate-skills-no-result-text">ไม่พบทักษะ</span>
                                        </div>
                                    </div> :
                                    <div className="box-candidate-skills-tag-control">
                                        {
                                            item.skills.map((items, indexs) => {

                                                return (
                                                    <div className="box-candidate-skills-tag-item-control" key={indexs}>
                                                        <div className="box-candidate-skills-tag" dangerouslySetInnerHTML={{ __html: highlightKeywords(items, keywordHightlightMod) }} >

                                                        </div>
                                                    </div>
                                                )

                                            })
                                        }
                                    </div>

                            }


                        </div>
                    }
                    <div className="candidate-bottom-control">

                        {
                            item.last_modified &&
                            <div className="candidate-bottom-history-control" >
                                <FontAwesomeIcon icon={faClockRotateLeft} className="candidate-bottom-icon" />
                                <span className="candidate-bottom-history">อัปเดทโปรไฟล์ล่าสุด: {new Date(item.last_modified).toLocaleDateString('th-TH', { year: "2-digit", month: "long", day: "2-digit" })}</span>
                            </div>

                        }
                        {
                            (versionMod == ApiConfig.version.internal || versionMod == ApiConfig.version.be_8external) &&
                            <div className="box-candidate-job-title-control">
                                <FontAwesomeIcon icon={faUserClock} className="box-candidate-job-title-icon" />
                                <span className="box-candidate-job-company">
                                    <span className="box-candidate-activity-link"
                                        onClick={() => clickActivityLogs(item)}>ดูประวัติการใช้งานแอดมิน</span>
                                </span>
                            </div>
                        }
                        {
                            !uuidPass && (versionMod == ApiConfig.version.preview || versionMod == ApiConfig.version.external) &&
                            <div className="open-candidate-resume-control">
                                <Button className="open-candidate-resume-button" onClick={() => clickInteresCandidate(item)}
                                ><FontAwesomeIcon icon={faLock} className="open-candidate-resume-button-icon"></FontAwesomeIcon>สนใจปลดล็อคข้อมูลผู้สมัคร</Button>
                            </div>

                        }
                        {
                            versionMod == ApiConfig.version.internal && isImportMode &&
                            <div className="open-candidate-resume-control">
                                <Button className="open-candidate-resume-button" onClick={() => selectCandidateImport(item, index)}
                                >{
                                        item.is_import ? "ยกเลิก" : "เลือก"}</Button>
                            </div>

                        }
                        {
                            uuidPass &&
                            <div className="open-candidate-resume-control">
                                {
                                    item.is_open && versionMod !== ApiConfig.version.internal ?
                                        <Button className="open-candidate-resume-button-active"
                                            onClick={() => sendCandidateToUseCredit(item.candidate_id)}>
                                            <FontAwesomeIcon icon={faLockOpen} className="open-candidate-resume-button-icon"></FontAwesomeIcon>
                                            ดูข้อมูลผู้สมัคร <span className="open-candidate-button-200-credit"> (ปลดล็อคแล้ว)</span></Button> :
                                        versionMod !== ApiConfig.version.internal &&
                                        <Button className="open-candidate-resume-button"
                                            onClick={() => sendCandidateToUseCredit(item.candidate_id)}>
                                            <FontAwesomeIcon icon={faLock} className="open-candidate-resume-button-icon"></FontAwesomeIcon>
                                            ปลดล็อคข้อมูลผู้สมัคร
                                            <span className="open-candidate-button-200-credit"> ({clientID == "TALENTSAUCE" ? "200" : "50"} credits)</span>
                                        </Button>
                                }


                            </div>
                        }
                    </div>

                </div>

                {
                    (versionMod == ApiConfig.version.be_8external || versionMod == ApiConfig.version.internal) &&

                    <div className="box-candidate-bottom-control">
                        <div className="d-flex">

                            {
                                (item.note && item.note.length > 0 && !item.note_show) ?
                                    <div className="box-note-title-control">
                                        <div className="box-note-show-text-control">
                                            <img src={NOTED_ICON} className="box-candidate-bottom-icon mb-auto mt-0" />
                                            <div className="d-flex flex-column">
                                                <span className="box-note-title">"{
                                                    (item.note_viewall && item.note.length >= 150) ?
                                                        <>
                                                            {item.note} < span className="box-note-expand-text"
                                                                onClick={() => viewAll(index)}
                                                            >ซ่อน</span>
                                                        </>
                                                        :

                                                        (item.note.length >= 150 && !item.note_viewall) ?
                                                            <>
                                                                {item.note.slice(0, 150)}  <span className="box-note-expand-text"
                                                                    onClick={() => viewAll(index)}
                                                                >ดูเพิ่มเติม</span>

                                                            </> : item.note

                                                }"</span>
                                                <span className="box-note-title-author">{item.note_updated_by} | {new Date(item.note_updated_at).toLocaleDateString('th-TH', { year: "2-digit", month: "long", day: "2-digit" })}</span>

                                            </div>
                                        </div>
                                        {
                                            !item.note_show && <div className="box-candidate-bottom-edit">
                                                <img src={NOTE_ICON} className="box-candidate-bottom-icon" />
                                                <span className="box-candidate-bottom-font my-auto" onClick={() => clickNote(index)}>แก้ไข</span>
                                            </div>
                                        }

                                    </div>
                                    :

                                    <div className="d-flex">
                                        <img src={NOTE_ICON} className="box-candidate-bottom-icon" />
                                        <span className="box-candidate-bottom-font" onClick={() => clickNote(index)}>เขียนโน๊ต</span>
                                    </div>
                            }

                            {
                                item.note_show &&
                                <div className="ml-auto d-flex" style={{ marginLeft: 'auto' }}>
                                    <div className="box-note-add-note" onClick={() => addNote(item, index)}>
                                        <FontAwesomeIcon icon={faCheck} className="box-note-add-note" />
                                        <span className="add-note-text">เพิ่มโน๊ต</span>
                                    </div>
                                    <div className="box-note-cancel-note" onClick={() => cancelNote(index)}>
                                        <FontAwesomeIcon icon={faClose} />
                                        <span className="add-cancel-text" >ยกเลิก</span>
                                    </div>
                                </div>
                            }

                        </div>

                        {
                            item.note_show &&
                            <Form.Control as="textarea" rows={3} className="box-note-text-area" value={item.preview_note}
                                onChange={(e) => onTextAreaChange(e, index)}
                                placeholder="เขียนโน็ตของคุณ.." />
                        }
                    </div>

                }

            </div>
        )
    }
    return (
        <>
            {
                showHistoryApply &&

                <HistoryApplyModal resume={currentResume} showDialog={showHistoryApply}
                    handleCloseHistoryApplyModal={handleCloseHistoryApplyModal}
                    keywordHightlight={keywordHightlightMod}
                    username={userName}></HistoryApplyModal>

            }
            {
                showHistoryJobs &&

                <HistoryJobsModal resume={currentResume} showDialog={showHistoryJobs}
                    handleCloseHistoryJobs={handleCloseHistoryJobsModal}
                    keywordHightlight={keywordHightlightMod}
                    username={userName}></HistoryJobsModal>
            }
            {
                isShowLogs &&

                <ActivityLogModal candidate_id={currentResume} handleCloseLogs={handleCloseLogs} isShowLogs={isShowLogs} email={userName}></ActivityLogModal>
            }
            <div className="box-candidate-grid-control">
                {
                    resumeModel && resumeModel.map((item: Resume, index: number) => {
                        return (

                            initValueCard(item, index)

                        )

                    })
                }
            </div>
            <Snackbar open={isModalSnack} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleCloseSnack} >
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                    Copied to clipboard
                </Alert>
            </Snackbar>

            {
                openProfileImg &&

                <Modal show={openProfileImg} onHide={() => setOpenProfileImg(false)}>
                    <ModalHeader closeButton></ModalHeader>
                    <ModalBody>
                        <img src={profileUrlOpen} className="modal-img-profile"></img>
                    </ModalBody>
                </Modal>
            }
        </>


    )
}

export default ResumeListComponent;