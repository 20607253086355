import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "./search-note.modal.scss";


const SearchNoteModal = ({ showDialog, handleCloseSearchNote, setSearchNote }: any) => {

    const [show, setShow] = useState(showDialog);
    const [noted, setNoted] = useState("");

    const onClickSearch = () => {

        setSearchNote(noted);
    }
    return (

        <>

            <Modal show={show} onHide={handleCloseSearchNote} className="search-note-modal">
                <Modal.Header closeButton>


                    <Modal.Title >

                        <span className="search-note-header"> ค้นหาโน๊ต</span>


                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="d-flex flex-column">

                        <Form.Control type="text" className="search-note-input" value={noted} onChange={(e) => setNoted(e.target.value)}
                            placeholder="ค้นหาโน๊ต" />

                        <button className="search-note-button" onClick={onClickSearch}>ค้นหา   <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></button>
                    </div>

                </Modal.Body>


            </Modal>
        </>
    )
}

export default SearchNoteModal;