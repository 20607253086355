import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ActivityLogModel, Log, ResultModel } from "../model/activity-log.model";
import { Resume } from "../model/result.model";
import ApiServices from "../services/api.service";
import "./activity-log.modal.scss";
import moment from 'moment-timezone';
const ActivityLogModal = ({ candidate_id, isShowLogs, handleCloseLogs, email }: any) => {
    const [show, setShow] = useState(isShowLogs);
    const [resume, setResume] = useState<Resume>(candidate_id);
    const [activityLogModel, setActivityLogModel] = useState<ActivityLogModel>();
    useEffect(() => {
        getLogs();
    }, [])
    const getLogs = () => {

        ApiServices().activityLog(resume.candidate_id, email).then((res: ActivityLogModel) => {
            setActivityLogModel(res);
        })
    }

    const convertLogType = (item: Log) => {

        if (item.candidate_log_type.toString() == "VIEW_RESUME") {
            return "ดูเรซูเม่ของผู้สมัคร"
        } else if (item.candidate_log_type.toString() == "VIEW_APPLIED_JOB") {
            return "ดูประวัติการสมัครงาน"
        } else if (item.candidate_log_type.toString() == "NOTE") {
            return "เขียนโน็ต “" + item.candidate_note + "”"
        } else if (item.candidate_log_type.toString() == "EXPORT_SC") {
            return "Export ไปที่งาน " + item.candidate_export_sc_job_id
        } else if (item.candidate_log_type.toString() == "VIEW_WORK_EXPERIENCES"){
            return "เปิดดูประสบการณ์ทำงานทั้งหมด"
        }
    }
    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("th-TH", {timeZone: tzString}));   
    }
    return (

        <>
            <Modal show={show} onHide={handleCloseLogs} className="activity-logs-control">
                <Modal.Header closeButton>
                    <span className="activity-logs-title">บันทึกกิจกรรม (Activity log)</span>
                </Modal.Header>

                <Modal.Body>

                    <div className="activity-logs-list-control">
                        {
                            (activityLogModel) &&
                            activityLogModel.candidate_logs.map((log: ResultModel, index: number) => {

                                return (

                                    <div className="d-flex flex-column activity-section-control" key={index}>
                                        <span className="activity-logs-list-date">
                                          
                                            {new Date(log.date).toLocaleDateString('th-TH', { year: "2-digit", month: "long", day: "2-digit" })}

                                        </span>
                                        {
                                            log.logs.map((item: Log, index2: number) => {


                                                return (
                                                    <div className="d-flex flex-column" key={index2}>

                                                        <div className={

                                                            index2 !== log.logs.length - 1 ?
                                                                "activity-logs-list-grid-detail activity-logs-list-grid-detail-divider" :
                                                                "activity-logs-list-grid-detail"

                                                        }>
                                                            <span className="activity-logs-list-grid-detail-text">
                                                                {moment.utc(item.candidate_log_at).tz("Asia/Bangkok").format("HH:mm")}
                                                                {/* {new Date(item.candidate_log_at).toISOString()} */}
                                                            </span>
                                                            <span className="activity-logs-list-grid-detail-text">
                                                                {item.candidate_log_by} {convertLogType(item)}</span>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }

                                    </div>


                                )
                            })

                        }
                        {
                            activityLogModel && activityLogModel.candidate_logs.length == 0 && <span className="activity-logs-title">ไม่มีบันทึก</span>
                        }


                    </div>

                </Modal.Body>


            </Modal>

        </>
    )
}

export default ActivityLogModal;