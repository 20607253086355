
import "./header.scss";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import LOGO_BRANDING from "../../assets/images/logo-branding-white.png";
import PROFILE_MOCK from "../../assets/images/profile-mock.png";
import { useContext, useEffect, useState } from "react";
import { auth } from "../../services/firebase.service";
import ApiServices from "../../services/api.service";
import { Timestamp } from "firebase/firestore";
import config from "../../config";
import ExitsLoginModal from "../../modal/exits-login.modal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SigninContext } from "../../context/signin.context";
import { Button, Dropdown } from "react-bootstrap";
import ApiConfig from "../../api-config";
import GAConfig from "../../ga-config";
import GAServices from "../../utils/ga-services";
import MysaService from "../../services/mysa.service";
import { ProfileModel } from "../../model/profile.model";
import { getUTMParams, handleNavigation } from "../../services/handler-route.service";
import tokenService from "../../services/token.service";
import AuthenMysa from "../../services/authen-mysa";


const HeaderComponent = ({ preview, setProfileMod }: any) => {

    const [toggle, setToggle] = useState(false);
    const [profileModel, setProfileModel] = useState<ProfileModel>();
    const [getLocalAuth, setLocalAuth] = useState(localStorage.getItem(config.localStorage.authTime));
    const [isLogOut, setLogOut] = useState(false);
    const navigate = useNavigate();
    const { setProfileModelContext, setIsHeaderGlobal, isHeaderGlobal, uuidPassGlobal, setUuidPassGlobal }: any = useContext(SigninContext)
    const { versionGlobal, setVersionGlobal }: any = useContext(SigninContext)
    const { isDomainMatch, setIsDomainMatch }: any = useContext(SigninContext)
    const { clientID }: any = useContext(SigninContext)
    const [isPreview, setIsPreview] = useState(preview);
    const location = useLocation();
    const { utmSource, utmMedium, version, keyToken } = getUTMParams(location);
    const [searchParams, setSearchParams] = useSearchParams();
    const domainArrayExternal = config.whitelist.external;
    const domainArrayInternal = config.whitelist.internal;
    const ToggleMenu = () => {
        if (toggle) {
            setToggle(false);
        } else {
            setToggle(true);
        }
    }

    useEffect(() => {
        if ((searchParams.get("client_id") == "TALENTSAUCE"
            || searchParams.get("client_id") == "HR9CLINIC"
            || searchParams.get("client_id") == "TALENTPOOL"
            || searchParams.get("client_id") == "REERACOEN"
            || searchParams.get("client_id") == "SCOUTOUT"
        ) && searchParams.get('keyToken')) {
            setIsHeaderGlobal('false');
            setUuidPassGlobal(searchParams.get('uuid'));
            tokenService.setIsHr9Clinic(true);
            tokenService.setLocalAccessToken(searchParams.get('keyToken')?.toString());
            getProfile();
        } else if ((searchParams.get("client_id") == "TALENTSAUCE"
            || searchParams.get("client_id") == "HR9CLINIC"
            || searchParams.get("client_id") == "TALENTPOOL"
            || searchParams.get("client_id") == "REERACOEN"
            || searchParams.get("client_id") == "SCOUTOUT")) {
            setIsHeaderGlobal('false');
            tokenService.setIsHr9Clinic(true);
            setVersionGlobal(ApiConfig.version.preview)
        } else if (location.pathname.includes("preview")) {
            setIsHeaderGlobal('true');
            setIsPreview(true);
            setVersionGlobal(ApiConfig.version.preview);
        }
        else {
            setIsHeaderGlobal('true');
            getProfile();
        }

    }, [])



    const getProfile = () => {

        MysaService.getProfile().then((res) => {
            if (res.data) {
                const profileMod: ProfileModel = res.data;
                setProfileModel(profileMod);
                setProfileModelContext(profileMod);
                setProfileMod(profileMod)
                let isVersion = false;
                domainArrayExternal.map((item: string, index: number) => {
                    if (profileMod.email.includes(item)) {
                        setVersionGlobal(ApiConfig.version.be_8external);
                        isVersion = true;
                    }
                })
                domainArrayInternal.map((item: string, index: number) => {
                    if (profileMod.email.includes(item)) {
                        setVersionGlobal(ApiConfig.version.internal);
                        isVersion = true;
                    }
                })

                if (!isVersion) {
                    setIsDomainMatch(true)
                    setVersionGlobal(ApiConfig.version.external);
                    // navigate('/search')
                } else {
                    setIsDomainMatch(true)
                    // navigate('/search')
                }

            }
        }).catch((err) => {
            handleNavigation(navigate, '/checking', utmSource, utmMedium, keyToken);
        })
    }


    const onfilterChange = (e) => {
        if (e == "logout") {
            AuthenMysa().LogOut().then((res) => {

                if (res) {
                    GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.logout }, versionGlobal)
                    handleNavigation(navigate, '/', utmSource, utmMedium, keyToken);
                }
            })
        } else if (e == "home") {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: "Click Home" }, versionGlobal)
            handleNavigation(navigate, '/checking', utmSource, utmMedium, keyToken, versionGlobal);
        }
        else {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.open_bookmark }, versionGlobal)
            handleNavigation(navigate, '/checking', utmSource, utmMedium, keyToken, versionGlobal, "/bookmarks");
        }


    }
    const backtoHome = () => {
        GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: "Click Logo" }, versionGlobal)
        handleNavigation(navigate, '/checking', utmSource, utmMedium, keyToken);
    }
    const clickLogin = () => {
        GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: "Login Button" }, versionGlobal)

        handleNavigation(navigate, '/', utmSource, utmMedium, keyToken);
    }
    return (
        <>
            {isLogOut && <ExitsLoginModal></ExitsLoginModal>}
            {
                isHeaderGlobal == 'true' && !clientID &&
                <div className="header">
                    <div className="collapse-control">
                        {
                            !isPreview && profileModel && <>
                                <div className="d-flex">
                                    <Dropdown
                                        className="right-login-profile-control"
                                        onClick={(e: any) => {
                                            if (e.target.value === "dropdown-basic") {
                                                //do something
                                            }
                                        }}
                                        onSelect={(e) => onfilterChange(e)}
                                    >
                                        <Dropdown.Toggle className="right-login-profile-control-dropdown" value="dropdown-basic">
                                            <img src={PROFILE_MOCK} className="right-profile-img"></img>
                                            <span className="right-profile-name">{profileModel.email}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="home">หน้าหลัก</Dropdown.Item>
                                            <Dropdown.Item eventKey="bookmark">บุ๊คมาร์คผู้สมัคร</Dropdown.Item>
                                            <Dropdown.Item eventKey="logout">ออกจากระบบ</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>


                            </>
                        }
                        {
                            isPreview &&
                            <Button className="login-control" onClick={clickLogin}>เข้าสู่ระบบ

                            </Button>

                        }

                        <div className="d-flex">
                            <img src={LOGO_BRANDING} className="logo-branding" onClick={backtoHome}></img>
                        </div>
                    </div>
                </div>
            }



        </>
    )
}

export default HeaderComponent;