import './loading.scss';
import LOADING_JSON from "../../assets/json/loading.json";
import Lottie from "lottie-react";
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import storage from '../../storage';
import tokenService from '../../services/token.service';
import ApiServices from '../../services/api.service';
import config from '../../config';
import { OauthModel } from '../../model/auth/oauth.model';
import MysaService from '../../services/mysa.service';
import { SigninContext } from '../../context/signin.context';
import { ProfileModel } from '../../model/profile.model';
import ApiConfig from '../../api-config';
const LoadingComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [codeVerifier, setCodeVerifier] = useState("");
    const [code, setCode] = useState("");
    const navigate = useNavigate();
    const { isDomainMatch, setIsDomainMatch }: any = useContext(SigninContext)
    const { versionGlobal, setVersionGlobal }: any = useContext(SigninContext)
    const domainArrayExternal = config.whitelist.external;
    const domainArrayInternal = config.whitelist.internal;
    useEffect(() => {

        if (searchParams.get('code') && searchParams.get('state')) {
            setCode(searchParams.get('code') + "")
            setCodeVerifier(localStorage.getItem(storage.code_verifier)!.toString())
        } else if (localStorage.getItem(storage.user)) {
            getProfile();
        }

    }, [])

    useEffect(() => {
        if (code && codeVerifier) {
            getAccessToken(code, codeVerifier);
        }

    }, [code && codeVerifier])


    const getAccessToken = (code, codeVerifier) => {

        ApiServices().oauthToken(code, codeVerifier, config.authen.redirect_uri).then((res: OauthModel) => {
            if (res) {
                tokenService.setLocalAccessToken(res.access_token);
                tokenService.setUser(res);
                getProfile();

            }

        })
    }
    const getProfile = () => {

        MysaService.getProfile().then((res) => {
            if (res.data) {
                const profileMod: ProfileModel = res.data;
                let isVersion = false;
                domainArrayExternal.map((item: string, index: number) => {
                    if (profileMod.email.includes(item)) {
                        setVersionGlobal(ApiConfig.version.be_8external);
                        isVersion = true;
                    }
                })
                domainArrayInternal.map((item: string, index: number) => {
                    if (profileMod.email.includes(item)) {
                        setVersionGlobal(ApiConfig.version.internal);
                        isVersion = true;
                    }
                })

                if(!isVersion){
                    setIsDomainMatch(true)
                    setVersionGlobal(ApiConfig.version.external);
                    navigate('/search')
                }else{
                    setIsDomainMatch(true)
                    navigate('/search')
                }
               
            }
        }).catch((err) => {
            navigate('/')
        })
    }

    return (
        <>
            <div className='loading-comp'>
                <div className='loading-control'>
                    <Lottie animationData={LOADING_JSON} loop={true} className="loading-img" />
                    <span className="loading-text">กำลังตรวจสอบข้อมูลการเข้าถึง</span>
                </div>


            </div>
        </>
    )
}

export default LoadingComponent;