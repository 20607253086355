import { signOut } from 'firebase/auth';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { auth } from '../services/firebase.service';
import Lottie from "lottie-react";
import ErrorAnimation from "../assets/json/error.json";
function ErrorResponseModal({ showOrg, closeErrorModal }: any) {
    const navigate = useNavigate();
    const [show, setShow] = useState(showOrg);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <Modal show={show} onHide={closeErrorModal}>


                <Modal.Body>
                    <div className='d-flex flex-column'>
                        <Lottie animationData={ErrorAnimation} loop={true} className="mx-auto" />
                        <p className='text-center'>มีข้อผิดพลาดในการค้นหา</p>
                        <Button variant="secondary" onClick={closeErrorModal}>ปิด</Button>
                    </div>


                </Modal.Body>

            </Modal>
        </>
    );
}

export default ErrorResponseModal;