import { signOut } from 'firebase/auth';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { auth } from '../services/firebase.service';

function ExitsLoginModal() {
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const clickOk = () => {
        signOut(auth);
        localStorage.clear();
        navigate("/")
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>มีการแจ้งเตือน</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>พบการ เข้าสู่ระบบ ซ้อน กรุณา Login ใหม่อีกครั้ง</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={clickOk}>ตกลง</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ExitsLoginModal;