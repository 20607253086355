import { useState } from "react";
import { Button, FormControl, Modal, ModalBody, ModalHeader } from "react-bootstrap"
import { Resume } from "../model/result.model";
import "./import-candidate.modal.scss";
import { CircularProgress, Divider, TextField } from "@mui/material";
import GAServices from "../utils/ga-services";
import GAConfig from "../ga-config";
import ApiConfig from "../api-config";
import ApiServices from "../services/api.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";


const ImportCandidateModal = ({ open, handleClose, resumeList, versionMod, email, clearSelectCandidate, sourceKey }: any) => {

    const [resumeListMod, setResumeListMod] = useState<Resume[]>(resumeList);
    const [jobID, setJobID] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const onValueChange = (value) => {
        if (checkSOFormat(value)) {
            setJobID(value);
            setErrorMessage("")
        } else {
            setErrorMessage("ไอดีงานผิด")
            setJobID(value);
        }

    }
    const checkSOFormat = (jobID: string) => {
        const regExp = jobID.match(/SO(\d{8})$/);
        if (regExp) {
            return true;
        } else {
            return false;
        }
    }
    const clickExport = () => {
        setIsSubmit(true);
        if (jobID) {
            if (checkSOFormat(jobID)) {
                const candidateList: Number[] = [];
                resumeList.map((item: Resume, index: Number) => {
                    candidateList.push(item.candidate_id);
                })


                if (versionMod == ApiConfig.version.external) {
                    ApiServices().postExportExternal(candidateList, jobID, email, sourceKey).then((res) => {
                        setIsSuccess(true);
                        setIsSubmit(false);
                        clearSelectCandidate();
                        GAServices().clickSendEvent(GAConfig.name.load, { param_action_on: GAConfig.value.export_candidate_success, param_value: jobID }, versionMod)

                    })
                } else {
                    ApiServices().postExport(candidateList, jobID, email,sourceKey).then((res) => {
                        setIsSuccess(true);
                        clearSelectCandidate();
                        setIsSubmit(false);
                        GAServices().clickSendEvent(GAConfig.name.load, { param_action_on: GAConfig.value.export_candidate_success, param_value: jobID }, versionMod)
                    })
                }
            } else {
                setIsSubmit(false);
                setJobID("");
                setErrorMessage("Job ID ไม่ถูกต้อง")
            }
        } else {
            setIsSubmit(false);
            setErrorMessage("กรุณาใส่ Job ID")
        }
    }
    const clickViewCandidate = (resume) => {

        const params = new URLSearchParams({
            resultObject: JSON.stringify(resume),
            email: email
        })
        window.open('/candidate-view?' + params, "_blank")
    }
    return (
        <>
            <div className="candidate-import-modal-main">
                <Modal show={open} onHide={handleClose} className="candidate-import-modal-main">
                    <ModalHeader closeButton></ModalHeader>
                    <ModalBody >
                        <div className="candidate-import-item-control">

                            {
                                !isSuccess &&
                                    isSubmit && jobID ?
                                    <div className="candidate-import-loading-control">
                                        <div className="candidate-import-loading-control-main">

                                            <CircularProgress className="candidate-import-loading-progress"></CircularProgress>
                                            <span className="candidate-import-loading-progress-text">กำลังส่งข้อมูล</span>
                                        </div>
                                    </div> :
                                    !isSuccess && resumeListMod &&
                                    <div className="d-flex flex-column">
                                        <span className="candidate-import-title">ผู้สมัครที่ถูกเลือก</span>
                                        <Divider className="candidate-import-divider"></Divider>
                                        {
                                            resumeListMod.map((item: Resume, index: number) => {

                                                return (
                                                    <>
                                                        <div className="d-flex flex-column" key={index}>
                                                            <div className="candidate-import-info-control">
                                                                <span className="candidate-import-info-text">{item.candidate_id}</span>
                                                                <span className="candidate-import-info-text">{item.first_name} {item.last_name}</span>
                                                                <Button className="candidate-import-button" onClick={() => clickViewCandidate(item)}>ดูรายละเอียด</Button>
                                                            </div>
                                                            {
                                                                index != resumeListMod.length - 1 &&
                                                                <Divider className="candidate-import-divider"></Divider>
                                                            }

                                                        </div>

                                                    </>
                                                )
                                            })
                                        }

                                        <span className="candidate-import-id-title">กรุณาใส่ ID งานที่ต้องการส่งผู้สมัคร</span>
                                        <TextField
                                            className="candidate-import-id-input"
                                            label="ex. SOXXXXXXXX"
                                            placeholder="ex. SOXXXXXXXX"
                                            variant="outlined"
                                            value={jobID}
                                            onChange={(e) => onValueChange(e.target.value)}
                                            required
                                        />
                                        {
                                            (isSubmit && errorMessage)
                                            &&
                                            <span className="input-search-error-text">{errorMessage}</span>
                                        }
                                        <Button className="candidate-import-submit" onClick={clickExport}>ส่งข้อมูล</Button>
                                    </div>
                            }
                            {
                                isSuccess &&
                                <div className="candidate-import-success-control">
                                    <div className="candidate-import-success-control-main">
                                        <FontAwesomeIcon icon={faCheck} className="candidate-import-success-progress"></FontAwesomeIcon>
                                        <span className="candidate-import-success-progress-text">ส่งข้อมูลสำเร็จ</span>
                                        <span className="candidate-import-success-progress-close" onClick={handleClose}>ปิด</span>
                                    </div>
                                </div>
                            }

                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default ImportCandidateModal;