
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import HistoryExperienceModel, { CandidateExperience } from "../model/history-experience";
import { Resume } from "../model/result.model";
import ApiServices from "../services/api.service";
import "./history-jobs.modal.scss";
import Moment from 'react-moment';
import moment from "moment";
import { CircularProgress } from "@mui/material";
const HistoryJobsModal = ({ resume, showDialog, handleCloseHistoryJobs, username, keywordHightlight }: any) => {

    const [show, setShow] = useState(showDialog);
    const [currentResume, setCurrentResume] = useState<Resume>(resume);
    const [historyExperienceModel, setHistoryExperienceModel] = useState<HistoryExperienceModel>();
    const [keywordHightlightMod, setKeywordHightlightMod] = useState<string[]>(keywordHightlight)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        getHistoryJobs();

    }, [])

    const getHistoryJobs = () => {
        if (currentResume) {
            setIsLoading(true);
            ApiServices().historyJobsLog(currentResume.candidate_id, username).then((res) => {
                if (res) {
                    setIsLoading(false);
                    setHistoryExperienceModel(res);
                }
            })
        }

    }
    function highlightKeywords(description: string, keywords: string[]): string {
        let highlightedDescription = description;

        try{
            keywords.forEach(keyword => {
                const regex = new RegExp(`(${keyword})`, 'gi');
                highlightedDescription = highlightedDescription.replace(regex, `<span style='background-color: yellow; font-weight:bold; color: black;'>$1</span>`);
            });
    
            return highlightedDescription;
        }catch{
            return description;
        }
    }
    const calculateExp = (date1, date2) => {
        const start = new Date(date1);
        const end = new Date(date2);
        const diffTime = Math.abs(Number(end) - Number(start));

        const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
        const diffMonths = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));

        if (diffYears == 0 && diffMonths > 0) {
            return diffMonths + " เดือน";
        } else if (diffYears == 0 && diffMonths == 0) {
            return "ไม่มีประสบการณ์ทำงาน";
        } else if (diffYears > 0 && diffMonths == 0) {
            return diffYears + " ปี ";
        }
        else {
            return diffYears + " ปี " + diffMonths + " เดือน";
        }
    }

    const convertDatetoBuddhist = (date1) => {
        const convDate = new Date(date1);

        return convDate.toLocaleDateString('th-TH', {
            year: '2-digit',
            month: 'short',
        });
    }

    const convertHtmlString = (value) => {
        if (value) {
            const formattedText = value.replace(/\n/g, '<br>');
            const formattedText2 = formattedText.replace(/•/g, '<li>').replace(/\n/g, '<br>');
            const formattedText3 = formattedText2.replace(/-/g, '<li>').replace(/\n/g, '<br>');
            const formattedText4 = formattedText3.replace("\n", '<br>');
            if (keywordHightlightMod) {
                return highlightKeywords(formattedText4, keywordHightlightMod)
            } else {
                return formattedText4;
            }
        } else {
            if (keywordHightlightMod) {
                return highlightKeywords(value, keywordHightlightMod)
            } else {
                return value;
            }
        }

    }

    return (

        <>

            <Modal show={show} onHide={handleCloseHistoryJobs} className="history-jobs-modal">
                <Modal.Header closeButton>


                    <Modal.Title >

                        <span className="history-jobs-header">ประสบการณ์ทำงานทั้งหมด</span>


                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="d-flex flex-column">
                        {
                            isLoading ?
                                <div className="history-jobs-loading-control">
                                    <div className="history-jobs-loading-main">
                                        <CircularProgress className="history-jobs-loading-progress"></CircularProgress>
                                        <span className="history-jobs-loading-text">กำลังโหลด . . . </span>
                                    </div>
                                </div> :

                                (historyExperienceModel && historyExperienceModel.candidate_experiences.length > 0) &&

                                historyExperienceModel.candidate_experiences.map((item: CandidateExperience, index: number) => {

                                    return (

                                        <div className="history-jobs-timeline-control" key={index}>

                                            <div className="history-jobs-timeline-dot">
                                                <div className={
                                                    index == 0 ? "dots" : "dots-gray"
                                                }></div>
                                                {
                                                    index != historyExperienceModel.candidate_experiences.length - 1 && <div className="line"></div>
                                                }


                                            </div>

                                            <div className="history-jobs-timeline-info">
                                                <span className="history-jobs-timeline-info-job-title">{item.work_experience_job_title}</span>
                                                <span className="history-jobs-timeline-info-job-company">{item.work_experience_company_name}</span>
                                                {
                                                    item.work_experience_start_date != null && item.work_experience_still_work_here  != null &&
                                                    <span className="history-jobs-timeline-info-job-date">
                                                        {convertDatetoBuddhist(item.work_experience_start_date)} - {

                                                            item.work_experience_still_work_here ? "ปัจจุบัน" : convertDatetoBuddhist(item.work_experience_end_date)
                                                        } • {item.work_experience_still_work_here ?
                                                            calculateExp(new Date(item.work_experience_start_date), new Date()) :
                                                            calculateExp(new Date(item.work_experience_start_date), new Date(item.work_experience_end_date))} </span>
                                                }

                                                <div className="history-jobs-timeline-info-job-desc" dangerouslySetInnerHTML={{ __html: convertHtmlString(item.work_experience_responsibilities) }}></div>
                                                {
                                                    index != historyExperienceModel.candidate_experiences.length - 1 &&
                                                    <div className="history-jobs-timeline-info-job-divider"></div>
                                                }

                                            </div>
                                        </div>
                                    )
                                })
                        }
                        {
                            (historyExperienceModel && historyExperienceModel.candidate_experiences.length == 0) &&

                            <span>ไม่มีประสบการณ์ทำงาน</span>
                        }



                    </div>

                </Modal.Body>


            </Modal>
        </>
    )
}

export default HistoryJobsModal;