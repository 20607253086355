import config from "../config";
import instance from "./auth.service";


const getProfile = () => {
    return instance.get(config.authen.leela.profile);
  };
  const registerProfile = () => {
    return instance.post(config.authen.leela.register_profile);
  };
  const registerCompany = () => {
    return instance.post(config.authen.leela.register_company);
  };

  
  const getDashboard = (company_uuid) => {
   
    return instance.get(config.authen.leela.dashboard+"?search=&sort=postedDateTime&sortDir=DESC&page=0&pageSize=100&jobStatus=ACTIVE");
  };

  const getDetailJob = (job_id) => {
   
    return instance.get(config.authen.leela.job+job_id)
  }
 
  
  const ScoutConnectService = {
    getProfile,
    getDashboard,
    registerProfile,
    registerCompany,
    getDetailJob
  };
  
  export default ScoutConnectService;