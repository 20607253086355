import "./search.scss";
import { useContext, useEffect, useRef, useState } from "react";
import InputSearchComponent, { InputSearchModel } from "./input/input-search";
import ResultSearchComponent from "./result/result-search";
import { ProfileModel } from "../../model/profile.model";
import MysaService from "../../services/mysa.service";
import config from "../../config";
import ApiConfig from "../../api-config";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import HeaderComponent from "../header/header";
import PostSearchModel from "../../model/post-search.model";
import { Sort } from "../../model/sort.model";
import { CircularProgress } from "@mui/material";
import ApiServices from "../../services/api.service";
import ResultModel, { Resume } from "../../model/result.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import ImportCandidateModal from "../../modal/import-candidate.modal";
import { SigninContext } from "../../context/signin.context";
import ContactUsModal from "../../modal/contact-us.modal";
import { HistoryCandidateModel } from "../../model/history-candidate.model";
import tokenService from "../../services/token.service";
import BreadcrumbComponent from "../breakcrumb/breadcrumb";

const SearchComponent = () => {
    const location = useLocation();
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [userName, setUserName] = useState("");
    const [versionMod, setVersionMod] = useState(ApiConfig.version.preview);
    const { versionGlobal }: any = useContext(SigninContext)
    const [isSubmiting, setIsSubmiting] = useState(false);
    const { clientID }: any = useContext(SigninContext);
    const [inputSearchModel, setInputSearchModel] = useState<PostSearchModel>();
    const [resultModel, setResultModel] = useState<ResultModel>();
    const [isImportMode, setIsImportMode] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [isPreview, setIsPreview] = useState(false);
    const [selectImport, setSelectImport] = useState<Resume[]>(new Array());
    const [isShowCart, setIsShowCart] = useState(false);
    const [sourceKey, setSourceKey] = useState("");
    const [isShowContactUs, setIsShowContactUs] = useState(false);
    const [historyCandidateList, setHistoryCandidateList] = useState<HistoryCandidateModel[]>();
    const [idCandidateInterest, setIdCandidateInterest] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [keyToken, setKeyToken] = useState("");
    const [bookmarksModel, setBookmarksModel] = useState<Resume[]>([]);
    useEffect(() => {
        if (profileMod) {
            setUserName(profileMod.email);
        } else {

        }

    }, [profileMod])

    useEffect(() => {
        if (inputSearchModel) {


        } else {
            const inputMod: PostSearchModel = {
                company: "",
                industry: "",
                job_description: "",
                job_title: "",
                keyword: "",
                limit: 20,
                location: "",
                page: 0,
                sort: Sort.EXP_AND_RELEVANT,
                note: ""
            }
            setInputSearchModel(inputMod);
        }
    }, [inputSearchModel])

    useEffect(() => {

        if (versionGlobal) {
            setVersionMod(versionGlobal);
        }
    }, [versionGlobal])

    useEffect(() => {

        if (location.pathname.includes("preview")
            && (searchParams.get("client_id") == "TALENTSAUCE" 
        || searchParams.get("client_id") == "HR9CLINIC"
        || searchParams.get("client_id") == "TALENTPOOL"
        || searchParams.get("client_id") == "REERACOEN"
        || searchParams.get("client_id") == "SCOUTOUT") && searchParams.get('keyToken')) {
            setIsPreview(false);
            getHistoryOpenCandidate(searchParams.get("client_id"), searchParams.get('keyToken'))
            setKeyToken(searchParams.get('keyToken')+"")
            tokenService.setLocalAccessToken(searchParams.get('keyToken'));
        } else if (searchParams.get("client_id") == "TALENTSAUCE" 
        || searchParams.get("client_id") == "HR9CLINIC"
        || searchParams.get("client_id") == "TALENTPOOL"
        || searchParams.get("client_id") == "REERACOEN"
        || searchParams.get("client_id") == "SCOUTOUT") {
            setIsPreview(true);
        } else if (location.pathname.includes("preview")) {
            initPreview();
            setIsPreview(true);
        } else if(tokenService.getLocalAccessToken()){
            setIsPreview(false);
            getHistoryOpenCandidate(clientID,tokenService.getLocalAccessToken())
            setKeyToken(tokenService.getLocalAccessToken()+"")
        }
        else {
            setIsPreview(false);
        }
    }, [])



    const handleCloseContactUs = () => {
        setIsShowContactUs(false);
    }


    const initPreview = () => {
        setUserName("preview");
        setVersionMod(ApiConfig.version.preview);
    }
    const getHistoryOpenCandidate = (client_id, keyToken) => {
        if (client_id == "TALENTSAUCE") {
            ApiServices().getHistoryOpenTalentSauce(keyToken).then((res) => {
                if (res) {
                    setHistoryCandidateList(res);
                }
            })
        } else {
            ApiServices().getHistoryOpen(keyToken).then((res) => {
                if (res) {
                    setHistoryCandidateList(res);
                }
            })
        }

    }

    const startCheckResult = (inputSearchModels: PostSearchModel, versionMod) => {
        let source_key = ""
        if (clientID == "TALENTSAUCE" || 
            clientID == "REERACOEN" || 
            clientID == "TALENTPOOL" || 
            clientID == "SCOUTOUT" && versionMod !== ApiConfig.version.internal) {
            source_key = config.sourceKey.tech;
        } else if(clientID == "TALENTSAUCE" || 
            clientID == "REERACOEN" || 
            clientID == "TALENTPOOL" || 
            clientID == "SCOUTOUT"
            && versionMod === ApiConfig.version.internal){
            source_key = config.sourceKey.internal_tech;
        }
        else {
            if (versionMod === ApiConfig.version.internal) {
                source_key = config.sourceKey.internal;
            } else if (versionMod === ApiConfig.version.be_8external) {
                source_key = config.sourceKey.external;
            } else {
                source_key = "";
            }
        }

        let sortFilter = "";
        if (inputSearchModels.sort) {
            sortFilter = inputSearchModels.sort;
        } else {
            sortFilter = Sort.EXP_AND_RELEVANT;
        }

        let user_name = userName;

        setIsSubmiting(true);
        setIsEdit(false);
        const inputDef = {
            ...inputSearchModels, // Copy the existing data
            source_key: source_key,     // Update a specific field
            user_name: user_name,
            sort: sortFilter,
            page: 0,
            limit: 20, // Update another field
        };
        setSourceKey(source_key);
        setInputSearchModel(inputDef);
        callApiPost(inputDef);
    }

    const updateListBookMark = (candidateID, isSave) => {
        return new Promise((resolve, reject) => {
            if (resultModel) {
                const resumeModEdit = { ...resultModel };
                let orgResumeModel = resumeModEdit.resumes;
                const updatedUsers = orgResumeModel.map(user =>
                    user.candidate_id === candidateID ? { ...user, is_bookmark: isSave } : user
                );

                resumeModEdit.resumes = updatedUsers;
                setResultModel(resumeModEdit);

                resolve(resumeModEdit);
            } else {
                reject(new Error("resultModel is not defined"));
            }
        });
    };
   
    const getFavorite = (result: ResultModel, isLoadMore: boolean, pageNumber) => {
        if (inputSearchModel) {
            ApiServices().getFavoriteCandidate(clientID, keyToken, inputSearchModel.source_key).then((res) => {
                if (res.status == 200) {
                    if (!isLoadMore) {
                        let orgResumeModel: Resume[] = [];
                        let bookmarkList = res.data as Resume[];

                        console.log(bookmarkList);
                        if (bookmarkList.length > 0) {

                            result.resumes.map((item: Resume, index: number) => {
                                item.is_open = false;
                                bookmarkList.filter(itemBookmark => itemBookmark.candidate_id == item.candidate_id).map((itemBookmark) => {
                                    item.is_bookmark = true;
                                })
                                if (historyCandidateList) {
                                    historyCandidateList.filter((value => Number(value.candidate_id) == item.candidate_id)).map((value: HistoryCandidateModel) => {
                                        console.log(Number(value.candidate_id) + " : " + item.candidate_id)
                                        item.is_open = true;
                                    })
                                }
                                orgResumeModel.push(item);
                            })


                        } else {
                            result.resumes.map((item: Resume, index: number) => {
                                item.is_open = false;
                                item.is_bookmark = false;
                                if (historyCandidateList) {
                                    console.log(historyCandidateList);
                                    historyCandidateList.filter((value => Number(value.candidate_id) == item.candidate_id)).map((value: HistoryCandidateModel) => {
                                        console.log(Number(value.candidate_id) + " : " + item.candidate_id)
                                        item.is_open = true;
                                    })
                                }
                                orgResumeModel.push(item);
                            })
                        }
                        const resume: ResultModel = result
                        resume.resumes = orgResumeModel;
                        console.log(resume);
                        setResultModel(resume);
                        setIsSubmiting(false);
                    } else {
                        if (resultModel) {
                            const resultMod = { ...resultModel };
                            resultMod.page = pageNumber;
                            let orgResumeModel: Resume[] = resultMod.resumes;
                            let bookmarkList = res.data as Resume[];
                            if (bookmarkList.length > 0) {

                                result.resumes.map((item: Resume, index: number) => {
                                    item.is_open = false;
                                    bookmarkList.filter(itemBookmark => itemBookmark.candidate_id == item.candidate_id).map((itemBookmark) => {
                                        item.is_bookmark = true;
                                    })
                                    if (historyCandidateList) {
                                        console.log(historyCandidateList);
                                        historyCandidateList.filter((value => Number(value.candidate_id) == item.candidate_id)).map((value: HistoryCandidateModel) => {
                                            console.log(Number(value.candidate_id) + " : " + item.candidate_id)
                                            item.is_open = true;
                                        })
                                    }
                                    orgResumeModel.push(item);
                                })

                                resultMod.resumes = orgResumeModel;

                            } else {
                                result.resumes.map((item: Resume, index: number) => {
                                    item.is_open = false;
                                    item.is_bookmark = false;
                                    if (historyCandidateList) {
                                        console.log(historyCandidateList);
                                        historyCandidateList.filter((value => Number(value.candidate_id) == item.candidate_id)).map((value: HistoryCandidateModel) => {
                                            console.log(Number(value.candidate_id) + " : " + item.candidate_id)
                                            item.is_open = true;
                                        })
                                    }
                                    orgResumeModel.push(item);
                                })
                                resultMod.resumes = orgResumeModel;
                            }
                            setResultModel(resultMod);
                            setIsSubmiting(false);
                        }

                    }
                }
            }).catch((err) => {
                if (!isLoadMore) {
                    let orgResumeModel: Resume[] = [];
                    result.resumes.map((item: Resume, index: number) => {
                        item.is_open = false;
                        item.is_bookmark = false;
                        if (historyCandidateList) {
                            historyCandidateList.filter((value => Number(value.candidate_id) == item.candidate_id)).map((value: HistoryCandidateModel) => {
                                console.log(Number(value.candidate_id) + " : " + item.candidate_id)
                                item.is_open = true;
                            })
                        }
                        orgResumeModel.push(item);
                    })
                    const resume: ResultModel = result
                    resume.resumes = orgResumeModel;
                    console.log(resume);
                    setResultModel(resume);
                    setIsSubmiting(false);
                } else {
                    if(resultModel){
                        const resultMod = { ...resultModel };
                        resultMod.page = pageNumber;
                        let orgResumeModel: Resume[] = resultMod.resumes;
                        result.resumes.map((item: Resume, index: number) => {
                            item.is_open = false;
                            item.is_bookmark = false;
                            if (historyCandidateList) {
                                console.log(historyCandidateList);
                                historyCandidateList.filter((value => Number(value.candidate_id) == item.candidate_id)).map((value: HistoryCandidateModel) => {
                                    console.log(Number(value.candidate_id) + " : " + item.candidate_id)
                                    item.is_open = true;
                                })
                            }
                            orgResumeModel.push(item);
                        })
                        resultMod.resumes = orgResumeModel;
                        setResultModel(resultMod);
                        setIsSubmiting(false);
                    }
                    
                }
            })
        }

    }
    const callApiPost = (inputSearchModel) => {

        ApiServices().postSearch(inputSearchModel).then((res: ResultModel) => {
            if (res) {
                const result = res;
                getFavorite(result, false, 0)
            }
        });
    }

    const selectCandidateImport = (resume, index) => {
        if (resultModel) {
            const resultMod = { ...resultModel };
            if (resultMod && resultMod.resumes) {
                if (resultMod.resumes[index].is_import) {
                    resultMod.resumes[index].is_import = false;
                } else {
                    resultMod.resumes[index].is_import = true;
                }
                setResultModel(resultMod);

                setSelectImport(resultMod.resumes.filter(item => item.is_import));
            }


        }


    }

    const clearCart = () => {
        if (resultModel) {
            const resultMod = { ...resultModel };
            if (resultMod && resultMod.resumes) {

                resultMod.resumes.filter(item => item.is_import).map((item, index) => {
                    resultMod.resumes[index].is_import = false;
                })

                setResultModel(resultMod);
                setSelectImport(new Array());
            }


        }

    }

    const handleCloseCart = () => {
        setIsShowCart(false);
    }

    const setLoadMore = (pageNumber) => {

        if (inputSearchModel) {
            const inputMod = { ...inputSearchModel }
            inputMod.page = Number(pageNumber);
            inputMod.limit = 20;
            setInputSearchModel(inputMod)
            ApiServices().postSearch(inputMod).then((res: ResultModel) => {

                if (res) {
                    const result = res as ResultModel;
                    if (resultModel && result.resumes.length > 0) {
                        getFavorite(result, true, pageNumber)
                    }
                }
            });
        }
    }


    const clickCart = () => {
        setIsShowCart(true);
    }

    const clearSelectCandidate = () => {
        setSelectImport(new Array());
        if (resultModel && resultModel.resumes.length > 0) {
            const resultMod = { ...resultModel };
            const updatedJsonArray = resultModel.resumes.map(item => ({
                ...item,
                is_import: false
            }));
            resultMod.resumes = updatedJsonArray;

            // Set the state to the new JSON object array
            setResultModel(resultMod);
            setIsImportMode(false);
        }

    }

    const clickInteresCandidate = (item: Resume) => {
        if (item) {
            setIdCandidateInterest(item.candidate_id + "")
            setIsShowContactUs(true)
        }

    }

    useEffect(() => {
        if (resultModel) {
            console.log(resultModel);
        }
    }, [resultModel])

    const setInternalHandleSourceKey = (inputSearchModels: PostSearchModel) => {
        let sortFilter = "";
        if (inputSearchModels.sort) {
            sortFilter = inputSearchModels.sort;
        } else {
            sortFilter = Sort.EXP_AND_RELEVANT;
        }

        let user_name = userName;

        setIsSubmiting(true);
        setIsEdit(false);
        const inputDef = {
            ...inputSearchModels, // Copy the existing data
            source_key: inputSearchModels.source_key,     // Update a specific field
            user_name: user_name,
            sort: sortFilter,
            page: 0,
            limit: 20, // Update another field
        };
        if(inputSearchModels.source_key){
            setSourceKey(inputSearchModels.source_key);
        }else{
            setSourceKey("");
        }
       
        setInputSearchModel(inputDef);
        callApiPost(inputDef);
    }

    return (

        <>

            <div className="">

                <HeaderComponent preview={isPreview} setProfileMod={setProfileMod} ></HeaderComponent>
                {/* <BreadcrumbComponent></BreadcrumbComponent> */}
                <div className={isEdit ? resultModel ? "search-main-control search-main-edit-mode" : "search-main-edit-mode-result-null" : "search-main-control"}


                >
                    {
                        isImportMode &&
                        <div className="result-cart-import">
                            <div className="result-cart-icon-control" onClick={clickCart}>
                                <FontAwesomeIcon icon={faCartShopping} className="result-cart-icon"></FontAwesomeIcon>
                                <div className="result-cart-badge-control">
                                    <span className="result-cart-badge-text">{
                                        selectImport.length}</span>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        inputSearchModel &&
                        <InputSearchComponent
                        profileModel={profileMod}
                        resultModel={resultModel ? resultModel : null}
                        inputSearchModel={inputSearchModel} startCheckResult={startCheckResult}
                        setInputSearchModel={setInputSearchModel}
                        setInternalHandleSourceKey={setInternalHandleSourceKey}
                        isEdit={isEdit} setLoadMore={setLoadMore} setIsEdit={setIsEdit}></InputSearchComponent>
                    }




                    {
                        isSubmiting &&
                        <div className="search-main-loading-control">
                            <div className="search-main-loading-main">
                                <CircularProgress className="search-main-loading-icon"></CircularProgress>
                                <span className="search-main-loading-text">กำลังค้นหาผู้สมัคร</span>
                            </div>
                        </div>

                    }
                    {
                        resultModel && !isSubmiting &&
                        <ResultSearchComponent
                            isImportMode={isImportMode}
                            resultModel={resultModel} postModel={inputSearchModel}
                            setLoadMore={setLoadMore}
                            updateListBookMark={updateListBookMark}
                            setIsImportMode={setIsImportMode}
                            setInputSearchModel={setInputSearchModel}
                            keyToken={keyToken}
                            clearCart={clearCart}
                            selectCandidateImport={selectCandidateImport}
                            startCheckResult={startCheckResult}
                            setIsShowContactUs={clickInteresCandidate}
                            isEdit={isEdit} setIsEdit={setIsEdit}></ResultSearchComponent>
                    }
                </div>
            </div>
            {
                isShowContactUs &&
                <ContactUsModal open={isShowContactUs} handleClose={handleCloseContactUs} item={idCandidateInterest}></ContactUsModal>
            }

            {
                isShowCart && userName &&
                <ImportCandidateModal 
                sourceKey={sourceKey}
                open={isShowCart} 
                handleClose={handleCloseCart} 
                resumeList={selectImport}
                versionMod={versionMod} 
                email={userName} 
                clearSelectCandidate={clearSelectCandidate}></ImportCandidateModal>
            }

            {/* {
                process.env.REACT_APP_ENVIRONMENT != "production" && <span style={{ position: "absolute", fontSize: "11px" }}>{versionMod}</span>
            } */}



        </>
    )
}

export default SearchComponent;