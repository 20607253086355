import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import LoginComponent from './components/login/login';
import { BrowserRouter, Route, Routes, useSearchParams } from "react-router-dom";
import { SigninContext } from './context/signin.context';
import LoadingComponent from './components/loading/loading';
import { ProfileModel } from './model/profile.model';
import ContactUsComponent from './components/contact-us/contact-us';
import ApiConfig from './api-config';
import CheckLoginComponent from './components/checking/check-login';
import SearchComponent from './components/search/search';
import CandidateViewComponent from './components/candidate-view/candidate-view';
import BookmarksComponent from './components/bookmarks/bookmarks';

const App = () => {
  const [profileModelContext, setProfileModelContext] = useState<ProfileModel>();
  const [isHeaderGlobal, setIsHeaderGlobal] = useState();
  const [uuidPassGlobal, setUuidPassGlobal] = useState("");
  const [clientID, setClientID] = useState("");
  const [versionGlobal, setVersionGlobal] = useState(ApiConfig.version.be_8external);
  const [isDomainMatch, setIsDomainMatch] = useState(ApiConfig.version.be_8external);
  useEffect(() => {

    if (window.location.search.includes("HR9CLINIC")) {
      setClientID("HR9CLINIC")
      document.documentElement.style.setProperty('--primary-soft-red', '#407FC025');
      document.documentElement.style.setProperty('--primary-red', '#407FC0');
      document.documentElement.style.setProperty('--primary-blue', '#4E62AD');
      document.documentElement.style.setProperty('--secondary-blue', '#EF4123');
      document.documentElement.style.setProperty('--button-primary-color', '#407FC0');
      document.documentElement.style.setProperty('--text-color', '#000000');
      document.documentElement.style.setProperty('--blue-line', '#B4DEFA');
      document.documentElement.style.setProperty('--header-color', '#333333');
    } else if(window.location.search.includes("TALENTSAUCE")){
      console.log("TALENTSAUCE");
      setClientID("TALENTSAUCE")
      document.documentElement.style.setProperty('--primary-soft-red', '#F266222');
      document.documentElement.style.setProperty('--primary-red', '#F26622');
      document.documentElement.style.setProperty('--primary-blue', '#2C3694');
      document.documentElement.style.setProperty('--secondary-blue', '#2C3694');
      document.documentElement.style.setProperty('--button-primary-color', '#F26622');
      document.documentElement.style.setProperty('--text-color', '#000000');
      document.documentElement.style.setProperty('--blue-line', '#2C3694');
      document.documentElement.style.setProperty('--header-color', '#333333');
    }else if(window.location.search.includes("TALENTPOOL")){
      console.log("TALENTPOOL");
      setClientID("TALENTPOOL")
      document.documentElement.style.setProperty('--primary-soft-red', '#4CAF50');
      document.documentElement.style.setProperty('--primary-red', '#4CAF50');
      document.documentElement.style.setProperty('--primary-blue', '#4CAF50');
      document.documentElement.style.setProperty('--secondary-blue', '#4CAF50');
      document.documentElement.style.setProperty('--button-primary-color', '#4CAF50');
      document.documentElement.style.setProperty('--text-color', '#000000');
      document.documentElement.style.setProperty('--blue-line', '#4CAF50');
      document.documentElement.style.setProperty('--header-color', '#333333');
    }else if(window.location.search.includes("REERACOEN")){
      console.log("REERACOEN");
      setClientID("REERACOEN")
      document.documentElement.style.setProperty('--primary-soft-red', '#5897fb');
      document.documentElement.style.setProperty('--primary-red', '#5897fb');
      document.documentElement.style.setProperty('--primary-blue', '#007BFF');
      document.documentElement.style.setProperty('--secondary-blue', '#1055c3');
      document.documentElement.style.setProperty('--button-primary-color', '#5897fb');
      document.documentElement.style.setProperty('--text-color', '#000000');
      document.documentElement.style.setProperty('--blue-line', '#5897fb');
      document.documentElement.style.setProperty('--header-color', '#333333');
    }else if(window.location.search.includes("SCOUTOUT")){
      console.log("SCOUTOUT");
      setClientID("SCOUTOUT")
      document.documentElement.style.setProperty('--primary-soft-red', '#ef4123');
      document.documentElement.style.setProperty('--primary-red', '#ef4123');
      document.documentElement.style.setProperty('--primary-blue', '#ef4123');
      document.documentElement.style.setProperty('--secondary-blue', '#ef4123');
      document.documentElement.style.setProperty('--button-primary-color', '#ef4123');
      document.documentElement.style.setProperty('--text-color', '#000000');
      document.documentElement.style.setProperty('--blue-line', '#ef4123');
      document.documentElement.style.setProperty('--header-color', '#333333');
    }
    else {
      document.documentElement.style.setProperty('--primary-soft-red', '#E96D5D25');
      document.documentElement.style.setProperty('--primary-red', '#E96D5D');
      document.documentElement.style.setProperty('--primary-blue', '#4E62AD');
      document.documentElement.style.setProperty('--secondary-blue', '#E3F0FE');
      document.documentElement.style.setProperty('--button-primary-color', '#4A90E2');
      document.documentElement.style.setProperty('--text-color', '#000000');
      document.documentElement.style.setProperty('--blue-line', '#B4DEFA');
      document.documentElement.style.setProperty('--header-color', '#333333');
    }
  }, [])
  return (
    <div className="App">

      <BrowserRouter>

        <SigninContext.Provider
          value={{ profileModelContext, setProfileModelContext, versionGlobal,
            clientID, setClientID,
            setVersionGlobal, isDomainMatch, setIsDomainMatch, isHeaderGlobal, setIsHeaderGlobal, uuidPassGlobal, setUuidPassGlobal }}
        >
          <Routes>

            <Route path="/" element={<LoginComponent />} />
            <Route path="/checking" element={<CheckLoginComponent />} />
            <Route path="/bookmarks" element={<BookmarksComponent />} />
            <Route path="/loading" element={<LoadingComponent />} />
            <Route path="/search" element={<SearchComponent />} />
            <Route path="/contact-us" element={<ContactUsComponent />} />
            <Route path="/candidate-view" element={<CandidateViewComponent />} />
            <Route path="/preview" element={<SearchComponent />} />

          </Routes>
        </SigninContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
