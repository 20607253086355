import config from "../../config";
import HeaderComponent from "../header/header";
import "./contact-us.scss";

const ContactUsComponent = () => {


    const onclickTel = () => {

        window.open("tel:020382639");
    }
    const onclickTel2 = () => {

        window.open("tel:0949246162");
    }
    const onclickPreview = () => {

        window.open(config.url + "/preview");
    }
    return (

        <>
            <HeaderComponent> </HeaderComponent>
                <div className="contact-us">
                    <div className="contact-us-text-control">
                        <span className="contact-us-text">ไม่สามารถใช้งานได้กรุณาติดต่อ</span>
                        <span className="contact-us-text-link" onClick={onclickTel}>02-038-2639</span>
                        <span className="contact-us-text-link" onClick={onclickTel2}>094-924-6162</span>
                        <span className="contact-us-text-preview">หากต้องการใช้ระบบ Talent Search สามารถใช้งาน Preview version ได้</span>
                        <span className="contact-us-text-link" onClick={onclickPreview}>คลิกที่นี่</span>
                    </div>
                </div>
           
        </>
    )
}

export default ContactUsComponent;