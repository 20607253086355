const local = {
    ga_4: "G-VPY7XMB1FN",
    ga: "",
    url: "http://localhost:4200",
    url_hr9_clinic: "http://localhost:4200",
    url_talent_sauce: "http://localhost:4200",
    url_talent_pool: "http://localhost:4200",
    url_reeracoen: "http://localhost:4200",
    url_scoutout: "http://localhost:4200",
    whitelist: {
        external: ["@baycoms.com", "@beryl8.com", "@inspex.co", "@maqe.com", "@techsauce.co", "@extend-it-resource.com"],
        internal: []
    },
    api: {
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",

    },
    hrclinic: {
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        base_url_techsauce: "https://testlambda.scoutout.net:444/talent-sauce/search",

    },
    authen: {
        sso_url: "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id: "SCOUT_CONNECT",
        redirect_uri: "http://localhost:4200/loading",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/user/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://leeladev.scoutout.net",
            profile: "/api/v1/manager/profile",
            register_profile: "/api/v1/register/manager",
            register_company: "/api/v1/register/company",
            dashboard: "/api/v1/dashboard/jobs",
            job: "/api/v1/jobs/"
        }
    },
    sourceKey: {
        external: "pjRBasXbRFGKwxpzya",
        internal: "DHMBvD4P28eCYKeeCw",
        tech: "jbQziHClw71HwuDZwH",
        internal_tech: "PbG5kIPe1OGuAfXjDT"
    },

    firebaseConfig: {
        apiKey: "AIzaSyB53hGL1Aox1xGvOUQ2BLgpJf_mmd7iEqM",
        authDomain: "scoutout-talent-search-t-944c7.firebaseapp.com",
        projectId: "scoutout-talent-search-t-944c7",
        storageBucket: "scoutout-talent-search-t-944c7.appspot.com",
        messagingSenderId: "281415957605",
        appId: "1:281415957605:web:c0c46ecf65a9d1502f1a8c"
    },
    localStorage: {
        authTime: "authTimeLocal"
    }
};
const dev = {
    ga_4: "G-xxxxxx",
    ga: "",
    url: "https://scoutout-talent-search-t-944c7.web.app",
    url_hr9_clinic: "https://hr-clinic-dev.web.app",
    url_talent_sauce: "https://talent-sauce-dev.web.app",
    url_talent_pool: "https://talent-pool-dev.web.app",
    url_reeracoen: "https://reeracoen-talent-dev.web.app",
    url_scoutout: "https://scoutout-talent-dev.web.app",
    whitelist: {
        external: ["@baycoms.com", "@beryl8.com", "@inspex.co", "@maqe.com", "@techsauce.co", "@extend-it-resource.com"],
        internal: []
    },
    api: {
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",

    },
    hrclinic: {
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        base_url_techsauce: "https://testlambda.scoutout.net:444/talent-sauce/search",

    },
    authen: {
        sso_url: "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id: "SCOUT_CONNECT",
        redirect_uri: "http://localhost:4200/loading",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/user/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://leeladev.scoutout.net",
            profile: "/api/v1/manager/profile",
            register_profile: "/api/v1/register/manager",
            register_company: "/api/v1/register/company",
            dashboard: "/api/v1/dashboard/jobs",
            job: "/api/v1/jobs/"
        }
    },
    sourceKey: {
        external: "pjRBasXbRFGKwxpzya",
        internal: "DHMBvD4P28eCYKeeCw",
        tech: "jbQziHClw71HwuDZwH",
        internal_tech: "PbG5kIPe1OGuAfXjDT"
    },

    firebaseConfig: {
        apiKey: "AIzaSyB53hGL1Aox1xGvOUQ2BLgpJf_mmd7iEqM",
        authDomain: "scoutout-talent-search-t-944c7.firebaseapp.com",
        projectId: "scoutout-talent-search-t-944c7",
        storageBucket: "scoutout-talent-search-t-944c7.appspot.com",
        messagingSenderId: "281415957605",
        appId: "1:281415957605:web:c0c46ecf65a9d1502f1a8c"
    },
    localStorage: {
        authTime: "authTimeLocal"
    }
};
const test = {
    ga_4: "G-VPY7XMB1FN",
    ga: "",
    url: "https://scoutout-talent-search-t-944c7.web.app",
    url_hr9_clinic: "https://hr-clinic-test-so.web.app",
    url_talent_sauce: "https://talent-sauce-test.web.app",
    url_talent_pool: "https://talent-pool-prod.web.app",
    url_reeracoen: "https://reeracoen-talent-test.web.app",
    url_scoutout: "https://scoutout-talent-test.web.app",
    whitelist: {
        external: ["@baycoms.com", "@beryl8.com", "@inspex.co", "@maqe.com", "@techsauce.co", "@extend-it-resource.com", "@jobnow.work", "@gmail.com"],
        internal: []
    },
    api: {
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",

    },
    hrclinic: {
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        base_url_techsauce: "https://testlambda.scoutout.net:444/talent-sauce/search",

    },
    authen: {
        sso_url: "https://d2zjecrqpgwbzj.cloudfront.net",
        client_id: "SCOUT_CONNECT",
        redirect_uri: "https://scoutout-talent-search-t-944c7.web.app/loading",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://mysatest.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/user/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://leelatest.scoutout.net",
            profile: "/api/v1/manager/profile",
            dashboard: "/api/v1/dashboard/jobs",
            register_profile: "/api/v1/register/manager",
            register_company: "/api/v1/register/company",
            job: "/api/v1/jobs/"
        }
    },
    sourceKey: {
        external: "pjRBasXbRFGKwxpzya",
        internal: "DHMBvD4P28eCYKeeCw",
        tech: "jbQziHClw71HwuDZwH",
        internal_tech: "PbG5kIPe1OGuAfXjDT"
    },

    firebaseConfig: {
        apiKey: "AIzaSyB53hGL1Aox1xGvOUQ2BLgpJf_mmd7iEqM",
        authDomain: "scoutout-talent-search-t-944c7.firebaseapp.com",
        projectId: "scoutout-talent-search-t-944c7",
        storageBucket: "scoutout-talent-search-t-944c7.appspot.com",
        messagingSenderId: "281415957605",
        appId: "1:281415957605:web:c0c46ecf65a9d1502f1a8c"
    },
    localStorage: {
        authTime: "authTimeLocal"
    }
};

const internalProd = {
    ga_4: "G-PGQ6LNX939",
    ga: "",
    url: "https://talent-search-internal-prod.web.app",
    url_hr9_clinic: "https://hr9-clinic.scoutout.co",
    url_talent_sauce: "https://talentsauce.co",
    url_talent_pool: "https://talent-pool-prod.web.app",
    url_reeracoen: "https://reeracoen-talent-test.web.app",
    url_scoutout: "https://scoutout-talent-test.web.app",
    whitelist: {
        external: ["@baycoms.com", "@beryl8.com", "@inspex.co", "@maqe.com", "@techsauce.co", "@extend-it-resource.com"],
        internal: ["@scoutout.net"]
    },
    authen: {
        sso_url: "https://account.scoutout.co",
        client_id: "SCOUT_CONNECT",
        redirect_uri: "https://talent-search-internal-prod.web.app/loading",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://account-io-api.scoutoutapi.com",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/user/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://connect-api.scoutoutapi.com",
            profile: "/api/v1/manager/profile",
            register_profile: "/api/v1/register/manager",
            register_company: "/api/v1/register/company",
            dashboard: "/api/v1/dashboard/jobs",
            job: "/api/v1/jobs/"
        }
    },
    api: {
        x_api_key: "SL9aSI8aSk4WT1uXW6wAB4tl3Z6bGH6v7OZq7mpM",
        base_url: "https://3e2417l4sg.execute-api.ap-southeast-1.amazonaws.com/internal_prod",

    },
    hrclinic: {
        x_api_key: "1TUCk4v9nYaKrYQqoELs7aEaiXXbuis91jbjiEw1",
        base_url: "https://bbyxui1fsg.execute-api.ap-southeast-1.amazonaws.com/production",
        base_url_techsauce: "https://dprod-api.scoutout.net/talent-sauce/search",

    },
    sourceKey: {
        external: "pjRBasXbRFGKwxpzya",
        internal: "DHMBvD4P28eCYKeeCw",
        tech: "jbQziHClw71HwuDZwH",
        internal_tech: "PbG5kIPe1OGuAfXjDT"
    },

    firebaseConfig: {
        apiKey: "AIzaSyAIyn-VDnZrbTn2D2e7I5aVXB2Apfg116Y",
        authDomain: "scoutout-talent-search.firebaseapp.com",
        projectId: "scoutout-talent-search",
        storageBucket: "scoutout-talent-search.appspot.com",
        messagingSenderId: "75642589724",
        appId: "1:75642589724:web:64acfeef58916c414e45d9"
    },
    localStorage: {
        authTime: "authTimeLocal"
    }

};
const prod = {
    ga_4: "G-PGQ6LNX939",
    ga: "",
    url: "https://talent.scoutout.co",
    url_hr9_clinic: "https://hr9-clinic.scoutout.co",
    url_talent_sauce: "https://talentsauce.co",
    url_talent_pool: "https://talent-pool-prod.web.app",
    url_reeracoen: "https://reeracoen-talent-test.web.app",
    url_scoutout: "https://scoutout-talent-test.web.app",
    whitelist: {
        external: ["@baycoms.com", "@beryl8.com", "@inspex.co", "@maqe.com", "@techsauce.co", "@extend-it-resource.com"],
        internal: ["@scoutout.net"]
    },
    authen: {
        sso_url: "https://account.scoutout.co",
        client_id: "SCOUT_CONNECT",
        redirect_uri: "https://talent.scoutout.co/loading",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://account-io-api.scoutoutapi.com",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/user/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        leela: {
            host_url: "https://connect-api.scoutoutapi.com",
            profile: "/api/v1/manager/profile",
            register_profile: "/api/v1/register/manager",
            register_company: "/api/v1/register/company",
            dashboard: "/api/v1/dashboard/jobs",
            job: "/api/v1/jobs/"
        }
    },
    api: {
        x_api_key: "1TUCk4v9nYaKrYQqoELs7aEaiXXbuis91jbjiEw1",
        base_url: "https://bbyxui1fsg.execute-api.ap-southeast-1.amazonaws.com/production",

    },
    hrclinic: {
        x_api_key: "1TUCk4v9nYaKrYQqoELs7aEaiXXbuis91jbjiEw1",
        base_url: "https://bbyxui1fsg.execute-api.ap-southeast-1.amazonaws.com/production",
        base_url_techsauce: "https://dprod-api.scoutout.net/talent-sauce/search",

    },
    sourceKey: {
        external: "pjRBasXbRFGKwxpzya",
        internal: "DHMBvD4P28eCYKeeCw",
        tech: "jbQziHClw71HwuDZwH",
        internal_tech: "PbG5kIPe1OGuAfXjDT"
    },

    firebaseConfig: {
        apiKey: "AIzaSyAIyn-VDnZrbTn2D2e7I5aVXB2Apfg116Y",
        authDomain: "scoutout-talent-search.firebaseapp.com",
        projectId: "scoutout-talent-search",
        storageBucket: "scoutout-talent-search.appspot.com",
        messagingSenderId: "75642589724",
        appId: "1:75642589724:web:64acfeef58916c414e45d9"
    },
    localStorage: {
        authTime: "authTimeLocal"
    }

};

const config = process.env.REACT_APP_ENVIRONMENT === 'production' ? prod
    : process.env.REACT_APP_ENVIRONMENT === 'internal' ? internalProd
        : process.env.REACT_APP_ENVIRONMENT === 'local' ? local
        : process.env.REACT_APP_ENVIRONMENT === 'dev' ? dev
            : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};