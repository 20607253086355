const GAConfig = {

    name: {
        click: "click_event",
        load: "load_event"
    },
   
    value: {
        searching: "Searching",
        login_sucess: "login successful",
        login_sucess_social: "login successful by social",
        create_profile: "create profile successful",
        logout: "logout successful",
        search_note: "searching note",
        open_history_apply: "Open history apply",
        close_history_apply: "Close history apply",
        open_history_experience: "Open history experience",
        close_history_experience: "Close history experience",
        open_search_note: "Open search note",
        close_search_note: "Close search note",
        open_export_candidate: "Open export candidate",
        close_export_candidate: "Close export candidate",
        export_all: "Export candidate all",
        select_export_candidate: "Select export candidate all",
        remove_export_candidate: "Remove export candidate all",
        show_activity_log: "Open Activity logs",
        show_history_job_apply: "Open history job apply",
        show_history_job_experience: "Open history job experience",
        download_resume: "Download resume",
        submit_export_candidate: "Submit export candidate",
        submit_export_candidate_all: "Submit export candidate all",
        export_candidate_success: "Export candidate success",
        export_candidate_all_success: "Export candidate all success",
        add_note_success: "add note success",
        show_note: "show note",
        hide_note: "hide note",
        cancel_note: "cancel note",
        view_more_note: "view more note",
        hide_view_more_note: "hide view more note",
        filter_change: "Filter change",
        prev_page: "Previous page",
        next_page: "Next page",
        open_bookmark: "Open Bookmark",
        save_bookmark: "Save Bookmark",
        remove_bookmark: "Remove Bookmark"
    }
}

export default GAConfig;