import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap"
import { Resume } from "../model/result.model";
import CANDIDATE from "../assets/images/candidate.png";
import "./history-apply.modal.scss";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApiServices from "../services/api.service";
import { HistoryJobsModel, Job } from "../model/history-jobs.model";
import { SigninContext } from "../context/signin.context";
const HistoryApplyModal = ({ resume, showDialog, handleCloseHistoryApplyModal, username, keywordHightlight }: any) => {
    const [show, setShow] = useState(showDialog);
    const [currentResume, setCurrentResume] = useState<Resume>(resume);
    const [keywordHightlightMod, setKeywordHightlightMod] = useState<string[]>(keywordHightlight)
    const [historyJobs, setHistoryJobs] = useState<HistoryJobsModel>();
    useEffect(() => {
        if (currentResume) {
            getHistory()
        }
    }, [currentResume])

    const getHistory = () => {


        ApiServices().getHistoryApply(username, currentResume.candidate_id).then((res: HistoryJobsModel) => {

            if (res) {
                setHistoryJobs(res);
            }
        })
    }
    function highlightKeywords(description: string, keywords: string[]): string {
        let highlightedDescription = description;

        try{
            keywords.forEach(keyword => {
                const regex = new RegExp(`(${keyword})`, 'gi');
                highlightedDescription = highlightedDescription.replace(regex, `<span style='background-color: yellow; font-weight:bold; color: black;'>$1</span>`);
            });
    
            return highlightedDescription;
        }catch{
            return description;
        }
    }
    return (

        <>
            <Modal show={show} onHide={handleCloseHistoryApplyModal} className="history-apply-modal">
                <Modal.Header closeButton>
                    {
                        currentResume.profile_image_url ?
                            <img src={currentResume.profile_image_url} className="history-apply-profile-img"></img> :
                            <img src={CANDIDATE} className="history-apply-profile-img"></img>
                    }

                    <Modal.Title >

                        <span className="history-apply-profile-name"> {currentResume.first_name} {currentResume.last_name}</span>


                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="history-apply-exp-control">
                        <div className="d-flex">
                            <FontAwesomeIcon icon={faBriefcase} className="history-apply-exp-icon"></FontAwesomeIcon>
                            <span className="history-apply-exp-title">ประวัติการสมัครงาน</span>
                        </div>
                        <div className="history-apply-divider"></div>

                       
                            {
                                historyJobs && historyJobs.jobs.map((item: Job, index: number) => {

                                    return (
                                        <div className="history-apply-exp-content"  key={index}>
                                        <div className="history-apply-exp-content-job-title-control">
                                            <span className="history-apply-exp-content-job-title">ตำแหน่ง: <span className="history-apply-exp-content-job-title-bold">{item.job_name}</span></span>
                                            <span className="history-apply-exp-content-job-title">บริษัท: <span className="history-apply-exp-content-job-title-bold">{item.company_name}</span></span>
                                            <span className="history-apply-exp-content-job-title">วันที่สมัคร: <span className="history-apply-exp-content-job-title-bold">{new Date(item.candidate_applied_date).toLocaleDateString('th-TH', { year: "2-digit", month: "long", day: "2-digit" })}</span></span>
                                        </div>
                                        </div>
                                    )
                                })
                            }

                    </div>

                </Modal.Body>

             
            </Modal>
        </>
    )


}

export default HistoryApplyModal;