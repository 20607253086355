

const ApiConfig = {
    version: {
        internal: "INTERNAL",
        external: "EXTERNAL",
        be_8external: "BE8_EXTERNAL",
        preview: "PREVIEW",
        none: "NONE"
    },
    api: {
        skill: "/skills",
        suggestion: "/suggestion",
        search: "/search",
        resume: "/resume",
        history_candidates: "/credit/history",
        history_jobs: "/candidate",
        export: "/export",
        export_request: "/export/request",
        candidate: {
            path: "/candidate/",
            note: "/note",
            logs: "/logs",
            experience: "/experiences",
            bookmark: "/bookmark"
        },
    },
    path: {
        url: "/",
        search: "/search",
        search_skill: "/search-skill",
        search_result: "/result",
        preview: {
            url: "/preview",
            search: "/preview/search",
            search_skill: "/preview/search-skill",
            search_result: "/preview/result"
        }
    }
}

export default ApiConfig;