import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "./contact-us.modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { SigninContext } from "../context/signin.context";
import config from "../config";


const ContactUsModal = ({ open, handleClose, item }: any) => {
    const { clientID }: any = useContext(SigninContext);

    const clickPackage = () => {


        if (clientID == "TALENTSAUCE") {
            window.open(config.url_talent_sauce + "/pricing", "_blank");
        } else {
            // window.open(config.url_hr9_clinic + "/pricing", "_blank");
        }

    }
    const clickEmail = () => {

        window.open("mailto:customerservice@scoutout.net");
    }
    const clickCall = (index) => {

        if (index == 1) {
            window.open("tel:020382639");
        } else {
            window.open("tel:0949246162");
        }

    }


    return (

        <>
            <div className="contact-us-main">

                <Modal className="contact-us-main" onHide={handleClose} show={open}>
                    <ModalHeader closeButton>

                    </ModalHeader>
                    <ModalBody>
                        <div className="contact-us-control">

                            <span className="contact-us-title">คุณกำลังสนใจผู้สมัคร ID</span>
                            <span className="contact-us-id-candidate">{item}</span>

                            <span className="contact-us-choose-text">เลือกช่องทางติดต่อสำหรับเปิดดูผู้สมัคร</span>
                            <div className="contact-us-choose-grid-control">
                                <div className="contact-us-grid-item" onClick={clickEmail}>
                                    <div className="contact-us-grid-icon-control">
                                        <FontAwesomeIcon icon={faMailBulk} className="contact-us-grid-icon"></FontAwesomeIcon>
                                        <span className="contact-us-grid-text">ส่งอีเมล</span>
                                    </div>

                                </div>
                                <div className="contact-us-grid-item" onClick={() => clickCall(1)}>
                                    <div className="contact-us-grid-icon-control">
                                        <FontAwesomeIcon icon={faPhone} className="contact-us-grid-icon"></FontAwesomeIcon>
                                        <span className="contact-us-grid-text">โทร</span>
                                        <span className="contact-us-grid-text-sub">02-038-2639, 094-924-6162</span>
                                    </div>
                                </div>
                                <div className="contact-us-grid-item" onClick={() => clickCall(2)}>
                                    <div className="contact-us-grid-icon-control">
                                        <FontAwesomeIcon icon={faPhone} className="contact-us-grid-icon"></FontAwesomeIcon>
                                        <span className="contact-us-grid-text">โทร</span>
                                        <span className="contact-us-grid-text-sub">094-924-6162</span>
                                    </div>
                                </div>
                                {
                                    clientID == "TALENTSAUCE" ?
                                    <div className="contact-us-grid-item" onClick={clickPackage}>
                                        <div className="contact-us-grid-icon-control">
                                            <FontAwesomeIcon icon={faCoins} className="contact-us-grid-icon"></FontAwesomeIcon>
                                            <span className="contact-us-grid-text">ซื้อแพ็กเกจ</span>
                                        </div>
                                    </div> : <div className="contact-us-grid-item" onClick={clickPackage}>
                                        <div className="contact-us-grid-icon-control">
                                            <FontAwesomeIcon icon={faCoins} className="contact-us-grid-icon"></FontAwesomeIcon>
                                            <span className="contact-us-grid-text">ADD LINE OA</span>
                                        </div>
                                    </div>

                                }
                                


                            </div>
                            <span className="contact-us-close-text" onClick={handleClose}>ปิด</span>
                        </div>



                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}

export default ContactUsModal;