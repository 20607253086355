import { useContext, useEffect, useState } from "react";
import { Resume } from "../../model/result.model";
import "./candidate-view.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faBirthdayCake, faBriefcase, faBusinessTime, faClock, faCopy, faIdCard, faLocation, faLocationDot, faMailBulk, faMoneyBill, faPhone, faStar, faTimeline, faUser } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useSearchParams } from "react-router-dom";
import ApiServices from "../../services/api.service";
import HistoryExperienceModel, { CandidateExperience } from "../../model/history-experience";
import moment from "moment";
import "../../modal/history-jobs.modal.scss";
import { SigninContext } from "../../context/signin.context";
import { Alert, Divider, Snackbar } from "@mui/material";
import GAServices from "../../utils/ga-services";
import GAConfig from "../../ga-config";
import { Button } from "react-bootstrap";



const CandidateViewComponent = () => {

    const { profileModelContext, uuidPassGlobal, clientID }: any = useContext(SigninContext);
    const [resumeMod, setResumeMod] = useState<Resume>()
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [isModalSnack, setIsModalSnack] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [historyExperienceModel, setHistoryExperienceModel] = useState<HistoryExperienceModel>();
    const [keywordHightlightMod, setKeywordHightlightMod] = useState<string[]>()
    useEffect(() => {

        if (searchParams) {
            if (searchParams.get("resultObject")) {
                const jsonParse = JSON.parse(searchParams.get("resultObject")!);
                setResumeMod(jsonParse);

            }

            if (searchParams.get("highlight")) {
                try {
                    const arrayString = searchParams.get("highlight")?.split(",");
                    setKeywordHightlightMod(arrayString)
                } catch {
                    setKeywordHightlightMod([""]);
                }

            }

            if (searchParams.get("email")) {
                setEmail(searchParams.get("email")!);
            } else {
                setEmail("admin");
            }

        }
    }, [location])

    useEffect(() => {

        if (resumeMod) {
            getHistoryJobs();
        }
    }, [resumeMod])

    const convertExp = (month) => {
        let yearValue = 0;
        let monthValue = 0;
        if (month > 12) {
            yearValue = Math.floor(month / 12);
            monthValue = Number(month % 12);
        } else {
            monthValue = Number(month % 12);
        }

        if (monthValue > 0 && yearValue === 0) {
            return (monthValue + " เดือน")
        } else if (monthValue === 0 && yearValue > 0) {
            return (yearValue + " ปี ")
        } else if (yearValue > 0 && monthValue > 0) {
            return (yearValue + " ปี " + monthValue + " เดือน")
        } else {
            return (yearValue + " ปี " + monthValue + " เดือน")
        }

    }

    const getHistoryJobs = () => {
        if (resumeMod) {
            ApiServices().historyJobsLog(resumeMod.candidate_id, email).then((res) => {
                if (res) {
                    setHistoryExperienceModel(res);
                }
            })
        }

    }

    const calculateExp = (date1, date2) => {
        const start = new Date(date1);
        const end = new Date(date2);
        const diffTime = Math.abs(Number(end) - Number(start));

        const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
        const diffMonths = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));

        // var start = moment(date1); //todays date
        // var end = moment(date2); // another date
        // var duration = moment.duration(end.diff(start));
        return diffYears + " ปี " + diffMonths + " เดือน";
    }
    const convertDatetoBuddhist = (date1) => {
        const convDate = new Date(date1);

        return convDate.toLocaleDateString('th-TH', {
            year: '2-digit',
            month: 'short',
        });
    }

    const convertHtmlString = (value) => {
        if (value) {
            const formattedText = value.replace(/\n/g, '<br>');
            const formattedText2 = formattedText.replace(/•/g, '<li>').replace(/\n/g, '<br>');
            const formattedText3 = formattedText2.replace(/-/g, '<li>').replace(/\n/g, '<br>');
            const formattedText4 = formattedText3.replace("\n", '<br>');
            if (keywordHightlightMod) {
                return highlightKeywords(formattedText4, keywordHightlightMod)
            } else {
                return formattedText4;
            }
        } else {
            if (keywordHightlightMod) {
                return highlightKeywords(value, keywordHightlightMod)
            } else {
                return value;
            }
        }

    }
    function highlightKeywords(description: string, keywords: string[]): string {
        let highlightedDescription = description;
        try {
            keywords.forEach(keyword => {
                const regex = new RegExp(`(${keyword})`, 'gi');
                highlightedDescription = highlightedDescription.replace(regex, `<span style='background-color: yellow; font-weight:bold; color: black;'>$1</span>`);
            });

            return highlightedDescription;
        } catch {
            return description;
        }

    }
    const handleCopyToClipboard = (id) => {
        const plainText = id;
        navigator.clipboard.writeText(plainText + "");
        setIsModalSnack(true);


    };

    const handleCloseSnack = () => {
        setIsModalSnack(false);
    }
    const clickDownloadResume = (item: Resume) => {
        let user_name = "";
        if (profileModelContext) {
            user_name = profileModelContext.email
        }

        ApiServices().downloadResume(item.bucket, item.key, user_name, item.candidate_id).then((res) => {
            if (res) {
                GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.download_resume, param_value: item.candidate_id }, "VIEW CANDIDATE")
                window.open(res.resume_url, '_blank');
            }

        })
    }
    return (
        <>
            <div className="candidate-view-main">
                {
                    resumeMod &&

                    <div className="candidate-view-item-control">
                        {
                            resumeMod.profile_image_url &&
                            <div className="candidate-view-item">
                                <img src={resumeMod.profile_image_url} className="candidate-view-profile-img"></img>
                            </div>
                        }

                        <div className="candidate-view-item mx-auto">
                            <div className="d-flex flex-column">
                                <div className="d-flex">
                                    <FontAwesomeIcon icon={faIdCard} className="candidate-view-icon" />
                                    <span className="candidate-view-title-id">ID ผู้สมัคร:</span>
                                </div>
                                <span className="candidate-view-title">
                                    <span className="candidate-view-value-id" onClick={() => handleCopyToClipboard(resumeMod.candidate_id)}>{resumeMod.candidate_id}</span>
                                    <FontAwesomeIcon icon={faCopy} onClick={() => handleCopyToClipboard(resumeMod.candidate_id)} className="candidate-view-value"></FontAwesomeIcon>
                                </span>
                            </div>
                        </div>
                        {
                            resumeMod.key &&
                            <Button className="candidate-view-resume-button" onClick={() => clickDownloadResume(resumeMod)}>ดู​ Resume</Button>
                        }
                        <div className="candidate-view-item-grid">
                            {
                                resumeMod.first_name && resumeMod.last_name &&
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faUser} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">ชื่อ-นามสกุล:</span>
                                    <span className="candidate-view-value">{resumeMod.first_name} {resumeMod.last_name}</span>
                                </div>
                            }
                            {
                                resumeMod.phone &&
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faPhone} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">เบอร์โทรศัพท์:</span>
                                    <span className="candidate-view-value">{resumeMod.phone}</span>
                                </div>
                            }
                            {
                                resumeMod.email &&
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faMailBulk} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">อีเมล:</span>
                                    <span className="candidate-view-value">{resumeMod.email}</span>
                                </div>
                            }
                            {
                                resumeMod.work_experience_salary &&
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faMoneyBill} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">เงินเดือนล่าสุด:</span>
                                    <span className="candidate-view-value">{resumeMod.work_experience_salary}</span>
                                </div>
                            }
                            {
                                resumeMod.date_of_birth &&
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faBirthdayCake} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">วันเกิด:</span>
                                    <span className="candidate-view-value">{new Date(resumeMod.date_of_birth).toLocaleDateString()}</span>
                                </div>
                            }
                            {
                                resumeMod.last_modified &&
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faClock} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">อัปเดตล่าสุด:</span>
                                    <span className="candidate-view-value">{new Date(resumeMod.last_modified).toLocaleDateString('th-TH', { year: "2-digit", month: "long", day: "2-digit" })}</span>
                                </div>
                            }
                            {
                                resumeMod.work_experience_job_title &&
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faBriefcase} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">ตำแหน่งงาน:</span>
                                    <span className="candidate-view-value">{resumeMod.work_experience_job_title}</span>
                                </div>
                            }
                            {
                                resumeMod.work_experience_company_name &&
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faLocationDot} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">บริษัท:</span>
                                    <span className="candidate-view-value">{resumeMod.work_experience_company_name}</span>
                                </div>
                            }
                            {
                                resumeMod.work_experience_company_name &&
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faBusinessTime} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">อายุงานล่าสุด:</span>
                                    <span className="candidate-view-value"> {
                                        resumeMod.work_experience_still_work_here ?
                                            calculateExp(resumeMod.work_experience_start_date, new Date()).toString() :
                                            calculateExp(resumeMod.work_experience_start_date, resumeMod.work_experience_end_date).toString()

                                    }</span>
                                </div>
                            }
                        </div>


                        <Divider className="candidate-view-divider"></Divider>


                        {
                            resumeMod.match_skills && resumeMod.match_skills.length > 0 &&

                            <>
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faStar} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title-black">ทักษะที่ตรงกับที่คุณค้นหา:</span>
                                </div>
                                <div className="box-candidate-skills-tag-control">
                                    {
                                        resumeMod.match_skills.map((items, indexs) => {

                                            return (
                                                <span className="box-candidate-skills-tag-highlight" key={indexs}>
                                                    {items}
                                                </span>
                                            )

                                        })
                                    }
                                </div>

                            </>

                        }

                        {
                            resumeMod.skills &&

                            <>
                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faAward} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title-black">ทักษะอื่นๆของผู้สมัคร:</span>
                                </div>
                                <div className="box-candidate-skills-tag-control">
                                    {
                                        resumeMod.skills.map((items, indexs) => {

                                            return (
                                                <span className="box-candidate-skills-tag" key={indexs}>
                                                    {items}
                                                </span>
                                            )

                                        })
                                    }
                                </div>

                            </>

                        }
                        {
                            resumeMod.note_show && resumeMod.note &&
                            <div className="candidate-view-item">
                                <FontAwesomeIcon icon={faBusinessTime} className="candidate-view-icon"></FontAwesomeIcon>
                                <span className="candidate-view-title">บันทึกล่าสุดจาก HR:</span>
                                <span className="candidate-view-value">{resumeMod.note}</span>
                            </div>
                        }
                        <Divider className="candidate-view-divider"></Divider>
                        <div className="history-jobs-modal d-flex flex-column">

                            {

                                (historyExperienceModel && historyExperienceModel.candidate_experiences.length > 0) &&

                                <div className="d-flex flex-column">
                                    <div className="candidate-view-item">
                                        <FontAwesomeIcon icon={faTimeline} className="candidate-view-icon"></FontAwesomeIcon>
                                        <span className="candidate-view-title-black">ประวัติการทำงาน:</span>
                                    </div>
                                    <div className="candidate-view-history-control">
                                        {

                                            historyExperienceModel.candidate_experiences.map((item: CandidateExperience, index: number) => {

                                                return (

                                                    <div className="history-jobs-timeline-control" key={index}>

                                                        <div className="history-jobs-timeline-dot">
                                                            <div className={
                                                                index == 0 ? "dots" : "dots-gray"
                                                            }></div>
                                                            {
                                                                index != historyExperienceModel.candidate_experiences.length - 1 && <div className="line"></div>
                                                            }


                                                        </div>

                                                        <div className="history-jobs-timeline-info">
                                                            <span className="history-jobs-timeline-info-job-title">{item.work_experience_job_title}</span>
                                                            <span className="history-jobs-timeline-info-job-company">{item.work_experience_company_name}</span>
                                                            <span className="history-jobs-timeline-info-job-date">อายุงาน:
                                                                {
                                                                    item.work_experience_start_date && (item.work_experience_end_date || item.work_experience_still_work_here) ?
                                                                    <>
                                                                        {convertDatetoBuddhist(item.work_experience_start_date)} - {

                                                                            item.work_experience_still_work_here ? "ปัจจุบัน" : convertDatetoBuddhist(item.work_experience_end_date)
                                                                        } • {item.work_experience_still_work_here ?
                                                                            calculateExp(new Date(item.work_experience_start_date), new Date()) :
                                                                            calculateExp(new Date(item.work_experience_start_date), new Date(item.work_experience_end_date))}
                                                                    </> : item.work_experience_still_work_here ?
                                                                    "ปัจจุบันยังทำอยู่ที่นี่" : "ไม่ระบุ"
                                                                }
                                                            </span>
                                                            <div className="history-jobs-timeline-info-job-desc" dangerouslySetInnerHTML={{ __html: convertHtmlString(item.work_experience_responsibilities) }}></div>
                                                            {
                                                                index != historyExperienceModel.candidate_experiences.length - 1
                                                                && <div className="history-jobs-timeline-info-job-divider"></div>
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                (historyExperienceModel && historyExperienceModel.candidate_experiences.length == 0) &&

                                <div className="candidate-view-item">
                                    <FontAwesomeIcon icon={faBriefcase} className="candidate-view-icon"></FontAwesomeIcon>
                                    <span className="candidate-view-title">ไม่พบประวัติการทำงานของผู้สมัคร:</span>
                                </div>
                            }
                        </div>

                    </div>


                }

            </div>
            <Snackbar open={isModalSnack} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleCloseSnack} >
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                    Copied to clipboard
                </Alert>
            </Snackbar>
        </>
    )
}

export default CandidateViewComponent;