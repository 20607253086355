
import { useContext, useEffect, useRef, useState } from "react";
import ResumeListComponent from "../../result/resume-list/resume-list";
import "./result-search.scss";
import FILTER_ICON from "../../../assets/images/filter.png";
import ResultModel, { Resume } from "../../../model/result.model";
import { Button, Dropdown, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApiConfig from "../../../api-config";
import { useLocation, useSearchParams } from "react-router-dom";
import EXPORT_CONNECT from "../../../assets/images/export-connect.png";
import EXPORT_CONNECT_ACTIVE from "../../../assets/images/export-connect-active.png";
import SEARCH_NOTE_IMG from "../../../assets/images/search-note.png";
import { faCaretDown, faCircleExclamation, faFilter, faMagnifyingGlass, faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { Sort } from "../../../model/sort.model";
import ARROW_UP from "../../../assets/images/arrowhead-up.png";
import SearchNoteModal from "../../../modal/search-note.modal";
import GAServices from "../../../utils/ga-services";
import GAConfig from "../../../ga-config";
import { SigninContext } from "../../../context/signin.context";
import HR9TEXT_IMG from "../../../assets/images/hr9-text.png";
import { CircularProgress } from "@mui/material";
import ApiServices from "../../../services/api.service";
import tokenService from "../../../services/token.service";
const ResultSearchComponent = ({ resultModel, postModel, setLoadMore, setIsEdit, isImportMode, setIsImportMode, startCheckResult,
    selectCandidateImport, setIsShowContactUs, clearCart, setInputSearchModel, updateListBookMark, keyToken }: any) => {
    const myHeaderResult = useRef<HTMLDivElement>(null);
    const [resultMod, setResultMod] = useState<ResultModel>();
    const { versionGlobal }: any = useContext(SigninContext);
    const [versionMod, setVersionMod] = useState(versionGlobal);
    const [isStartImport, setIsStartImport] = useState(false);
    const [isShowImportCandidateAll, setIsShowImportCandidateAll] = useState(false);
    const { clientID }: any = useContext(SigninContext);
    const [isImportMod, setImportMod] = useState(isImportMode);
    const location = useLocation();
    const [isCanLoad, setIsCanLoad] = useState(true)
    const [showSearchNote, setShowSearchNote] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [highlightWord, setHighlightWord] = useState<string[]>([]);
    useEffect(() => {
        if (resultModel) {
            if(postModel){
               
                const jobStringArr = postModel.job_title.split(" ");
                if(postModel.skills){
                    if(postModel.skills.length > 0){
                        postModel.skills.map((item: String) => {
                            jobStringArr.push(item)
                        })
                    }
                }
              
                setHighlightWord(jobStringArr);
                
            }
            setResultMod(resultModel);
        }
    }, [resultModel])

    useEffect(() => {
        if (resultMod) {
            setIsCanLoad(true);
            setIsLoadingMore(false);
        } 
    }, [resultMod && resultModel])

    useEffect(() => {
        setImportMod(isImportMode);
    }, [isImportMode])
    useEffect(() => {

        if (versionGlobal) {
            setVersionMod(versionGlobal);
        }
    }, [versionGlobal])
    useEffect(() => {
        if (isLoadingMore && resultMod) {
            setLoadMore(resultMod.page + 1);
        }
    }, [isLoadingMore])
    useEffect(() => {
        if (location.pathname.includes("client_id")) {

        }
    }, [])

    

    const handleShowImportCandidateAll = (e) => {

    }

    const onfilterChange = (e) => {
        if (postModel && e) {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.filter_change, param_value: e }, versionMod)
            postModel.sort = e;
            handleCloseSearchNoteModal();
            setInputSearchModel(postModel);
            startCheckResult(postModel, versionMod);
            setIsEdit(false);

        }
    }

    const handleCloseSearchNoteModal = () => {
        if (showSearchNote) {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.close_search_note }, versionMod)
            setShowSearchNote(false);
        } else {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.open_search_note }, versionMod)
            setShowSearchNote(true);
        }
    }

    const handleShowImportCandidate = () => {
        if (isImportMod) {
            setImportMod(false);
            setIsImportMode(false);
            clearCart();
        } else {
            setImportMod(true);
            setIsImportMode(true);
        }

    }

    const handleScroll = (event) => {
        setIsLoadingMore(true);
        setIsCanLoad(false);
    }

    const saveFavorite = (item) => {
        const resume = item as Resume;
        if (resume.candidate_id) {
            ApiServices().saveFavoriteCandidate(clientID, postModel.source_key, resume.candidate_id, keyToken).then((res) => {
                if (res.status == 200) {
                    GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.save_bookmark }, versionGlobal)
                    updateListBookMark(resume.candidate_id, true).then((res) => {
                        console.log(res);
                        setResultMod(res);
                    })
                }

            })
        }
    }
    const removeFavorite = (item) => {
        const resume = item as Resume;
        if (resume.candidate_id) {
            ApiServices().deleteFavoriteCandidate(clientID, postModel.source_key, resume.candidate_id, keyToken).then((res) => {
                console.log(res);
                if (res.status == 200) {
                    GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.remove_bookmark }, versionGlobal)
                    updateListBookMark(resume.candidate_id, false).then((res) => {
                        setResultMod(res);
                    })
                }

            })
        }
    }
   
    const executeScroll = () => {

        if (myHeaderResult.current) {
            console.log("scroll to top")
            myHeaderResult.current.scrollTop = 0;
        }
    }
    const setSearchNoteClick = (note: string) => {
        if (postModel && note) {
            GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.search_note, param_value: note }, versionMod)
            postModel.note = note;
            handleCloseSearchNoteModal();
            startCheckResult(postModel, versionMod);
            setIsEdit(false);

        }
    }

    const clickInteresCandidate = (resume) => {
        GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: "interest candidate", param_value: resume.candidate_id }, versionMod)
        setIsShowContactUs(resume);
    }

    return (

        <>
            <div className="result-search-main"  >
                <div  ></div>

                {
                    resultMod && resultMod.resumes.length > 0 ?
                        <div className="result-box-candidate-control"
                            ref={myHeaderResult}  >
                            <div className="scroll-top-div d-flex" onClick={executeScroll}>

                                <img src={ARROW_UP} className="scroll-img mx-auto"></img>
                            </div>


                            <>
                                <div className="box-candidate-header-control" >
                                    <div className="d-flex flex-column w-100"    >
                                        {
                                            (resultMod) &&
                                            <span className="text-result-header"> ผลการค้นหาทั้งหมด {resultModel.count} โปรไฟล์</span>
                                        }
                                        {
                                            (resultModel && versionMod == ApiConfig.version.internal) &&
                                            isStartImport && <Form.Check onChange={(e) => handleShowImportCandidateAll(e)} checked={isShowImportCandidateAll}
                                                type='checkbox' className="box-candidate-import-all-text" label={"เลือกทั้งหมด (" + resultModel.count + " โปรไฟล์)"}
                                            />
                                        }


                                    </div>
                                    <div className="filter-control ml-auto">

                                        {
                                            resultMod &&
                                            <>
                                                {
                                                    (versionMod == ApiConfig.version.internal)
                                                    &&

                                                    <div className=
                                                        {
                                                            isImportMod ? "button-export-to-active order-md-0 order-1" : "button-export-to order-md-0 order-1"
                                                        }
                                                        onClick={handleShowImportCandidate}>
                                                        <div className="button-export-text-control">
                                                            <span className="button-export-text">Export to HR9</span>
                                                        </div>
                                                    </div>

                                                }
                                                {
                                                    (versionMod != ApiConfig.version.preview
                                                        && versionMod != ApiConfig.version.external) &&
                                                    <div className="search-note-control" onClick={handleCloseSearchNoteModal}>

                                                        <FontAwesomeIcon icon={faMagnifyingGlass} className="search-note-icon" />
                                                        <span className="search-note-text">ค้นหาโน๊ต</span>
                                                    </div>
                                                }




                                                <Dropdown
                                                    className="filter-control  order-md-1 order-0"
                                                    onClick={(e: any) => {
                                                        if (e.target.value === "dropdown-basic") {
                                                            //do something
                                                        }
                                                    }}
                                                    onSelect={(e) => onfilterChange(e)}
                                                >
                                                    <Dropdown.Toggle className="filter-dropdown" value="dropdown-basic">
                                                        <FontAwesomeIcon icon={faFilter} className="filter-img" />
                                                        <span className="filter-text">{(postModel.sort == Sort.EXP_AND_APPLIED_DATE) ? "อัปเดตล่าสุด" : (postModel.sort == Sort.EXP_AND_RELEVANT) ? "ที่เกี่ยวข้องมากสุด" : "ที่เกี่ยวข้องมากสุด"}</span>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey={Sort.EXP_AND_RELEVANT}>ที่เกี่ยวข้องมากสุด</Dropdown.Item>
                                                        <Dropdown.Item eventKey={Sort.EXP_AND_APPLIED_DATE}>อัปเดตล่าสุด</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                {/* {
                                        (resumeImport && resumeImport.length > 0 && isMobile) &&
                                        <span className="import-connect-text-red order-3" onClick={onClickExportCandidateMobile}>export ({resumeImport.length} โปรไฟล์)</span>
                                    } */}
                                            </>

                                        }
                                    </div>
                                </div>
                                {
                                    resultMod && resultMod.resumes &&
                                    <ResumeListComponent
                                        clickInteresCandidate={clickInteresCandidate}
                                        saveFavorite={saveFavorite}
                                        removeFavorite={removeFavorite}
                                        resumeModelPass={resultMod.resumes}
                                        versionModel={versionMod}
                                        isImportMode={isImportMod}
                                        keywordHightlight={highlightWord}
                                        selectCandidateImport={selectCandidateImport}></ResumeListComponent>
                                }


                                {
                                    isLoadingMore ?
                                        <div className="loadmore-candidate-control">
                                            <div className="loadmore-candidate-main">
                                                <CircularProgress className="loadmore-progress"></CircularProgress>
                                                <span className="loadmore-text">กำลังโหลด</span>
                                            </div>
                                        </div> :
                                        resultMod.resumes.length != resultMod.count ?
                                            <Button className="button-load-candidate-more" onClick={handleScroll}>โหลดเพิ่มเติม</Button>
                                            : null
                                }
                            </>
                        </div> :
                        <>
                            <div className="no-resumes-control">

                                <div className="no-resumes-item-control">
                                    <FontAwesomeIcon icon={faCircleExclamation} className="no-resumes-icon"></FontAwesomeIcon>

                                    <span className="no-resumes-text">ไม่พบ Resumes ที่ค้นหา</span>
                                    <Button className="no-resumes-button">แก้ไขการค้นหา</Button>

                                </div>
                            </div>

                        </>
                }

            </div>
            {
                showSearchNote &&
                <SearchNoteModal showDialog={showSearchNote} handleCloseSearchNote={handleCloseSearchNoteModal} setSearchNote={setSearchNoteClick} ></SearchNoteModal>
            }
        </>
    )
}

export default ResultSearchComponent;