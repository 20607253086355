import config from "../config";
import instance from "./auth.service";
import tokenService from "./token.service";


const getProfile = () => {
  console.log(tokenService.getLocalAccessToken());
  return instance.get(config.authen.api.profile);
};


const MysaService = {
  getProfile
};

export default MysaService;