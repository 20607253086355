
import "./login.scss";
import COVER_LANDING from "../../assets/images/cover-landing.png";
import LOGO_BRANDING from "../../assets/images/logo-branding.png";
import LOGIN_GOOGLE from "../../assets/images/btn-login-google.png";
import Button from "react-bootstrap/esm/Button";
import ApiServices from "../../services/api.service";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { SigninContext } from "../../context/signin.context";
import storage from "../../storage";
import MysaService from "../../services/mysa.service";

const LoginComponent = () => {
    const navigate = useNavigate();
    const [isInternal, setIsInternal] = useState(false)
    const { setProfileModelContext }: any = useContext(SigninContext)
   


    const authen = () => {

        authenticateUrl();
    }
    const authenticateUrl = async () => {
        const codeVerifier = await ApiServices().randomString(56);
      
        localStorage.setItem(storage.code_verifier, codeVerifier);
        const state = ApiServices().randomString(config.authen.length);
        if(codeVerifier){
            const codeChallenge = await ApiServices().PKCEencoding(codeVerifier);
            if(codeChallenge){
                const params = new URLSearchParams()
                params.append('redirect_uri', config.authen.redirect_uri);
                params.append('state', state);
                params.append('code_challenge',codeChallenge)
                params.append('response_type', 'code');
                params.append('code_challenge_method', 'S256')
                params.append('client_id',config.authen.client_id);
             
                if(params){
                    const urlReturn = config.authen.sso_url + "?" + new URLSearchParams(params)
                    window.open(urlReturn, "_self");
                }
           
            }
        }
       
    }
   
    const getProfile = () => {

        MysaService.getProfile().then((res) => {
            if (res.data) {
                setProfileModelContext(res.data);
                navigate('/search')
            }
        }).catch((err) => {
            navigate('/')
        })
    }

    useEffect(() => {
        getProfile();
      
    }, [])

    return (
        <>

            <div className="login-page">
                <div className="left-control">
                    <img src={COVER_LANDING} className="left-img"></img>
                </div>
                <div className="right-control">
                    <div className="right-div-control">
                        <img src={LOGO_BRANDING} className="right-img-branding"></img>
                        {/* <span className="right-login-text">เข้าสู่ระบบเพื่อเริ่มใช้งาน</span> */}
                        <Button className="right-login-scoutout" onClick={authen}>เข้าสู่ระบบด้วย ScoutOut Login</Button>
                        {/* <img src={LOGIN_GOOGLE} className="right-login-google" onClick={signInWithGoogle}></img>
                        <span className="text-or mx-auto">หรือ</span>
                        <Form className="d-flex flex-column">
                          
                            <Form.Control type="email" className="right-input-form" required placeholder="อีเมล" onChange={(e) => onEmailChange(e)} />
                            <Form.Control type="password" className="right-input-form" required placeholder="รหัสผ่าน" onChange={(e) => onPasswordChange(e)} />
                            <span className="right-login-error">{error}</span>
                            <span className="right-input-link">ลืมรหัสผ่าน</span>
                            <Button className="right-input-button" onClick={(e) => onSubmit(e)} type="submit">
                                เข้าสู่ระบบ
                            </Button>
                        </Form> */}
                    </div>
                </div>
            </div>


        </>
    )
}

export default LoginComponent;