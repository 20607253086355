import { initializeApp } from "firebase/app";
import config from "../config"
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, orderBy, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import ErrorResponseModal from "../modal/error-response.modal";
import ApiConfig from "../api-config";
import tokenService from "./token.service";
import axios from "axios";
var shajs = require('sha.js')
window.Buffer = window.Buffer || require("buffer").Buffer; 
export default function ApiServices() {
  
    return {
        async getSkill() {

            return fetch(config.api.base_url + ApiConfig.api.skill, {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                }
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        },
        async getHistoryApply(username:string, candidate_id) {
          
            const params = new URLSearchParams({
                user_name: username,
                candidate_id: candidate_id
            })
            return fetch(config.api.base_url + ApiConfig.api.history_jobs + "?" + params,  {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                }
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        }
        ,
        async getHistoryOpen(keyToken) {
          
            return axios.get(config.hrclinic.base_url + ApiConfig.api.history_candidates,  {
                headers: {
                    'x-api-key': config.api.x_api_key,
                    'scoutout-authorization': keyToken,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => Promise.reject(err))
        }
        ,
        async getHistoryOpenTalentSauce(keyToken) {
          
            return axios.get(config.hrclinic.base_url_techsauce + ApiConfig.api.history_candidates,  {
                headers: {
                    'scoutout-authorization': keyToken,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => Promise.reject(err))
        }
        ,
        async saveFavoriteCandidate(client_id, source_key, candidate_id, keyToken){
            let url = config.hrclinic.base_url;
            if (client_id == "TALENTSAUCE") {
                url = config.hrclinic.base_url_techsauce
            }

            const objectResult = {
                source_key: source_key
            }
          
            console.log(candidate_id);
            return axios.post(url + ApiConfig.api.candidate.path + candidate_id + ApiConfig.api.candidate.bookmark, objectResult, {
                headers: {
                    'x-api-key': config.hrclinic.x_api_key,
                    'scoutout-authorization': keyToken,
                    'Source-Key' : source_key,
                    'content-type': 'application/json'
                },
            }
            ).then(res => res).catch(err => Promise.reject(err))
        },
        async deleteFavoriteCandidate(client_id, source_key, candidate_id, keyToken){
            let url = config.hrclinic.base_url;
            if (client_id == "TALENTSAUCE") {
                url = config.hrclinic.base_url_techsauce
            }
            return axios.delete(url + ApiConfig.api.candidate.path + candidate_id + ApiConfig.api.candidate.bookmark,  {
                headers: {
                    'scoutout-authorization': keyToken,
                    'Source-Key' : source_key,
                    'x-api-key': config.hrclinic.x_api_key,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res).catch(err => Promise.reject(err))
        },
        async getFavoriteCandidate(client_id, keyToken, source_key){
            let url = config.hrclinic.base_url;
            if (client_id == "TALENTSAUCE") {
                url = config.hrclinic.base_url_techsauce
            }
            console.log(url);
            return axios.get(url + "/bookmarks",  {
                headers: {
                    'scoutout-authorization': keyToken,
                    'Source-Key' : source_key,
                    'x-api-key': config.hrclinic.x_api_key,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res).catch(err => Promise.reject(err))
        },
        async downloadResume(bucket: string, key: string, user_name: string, candidate_id) {

            return fetch(config.api.base_url + ApiConfig.api.resume + "?" + new URLSearchParams({
                bucket: bucket,
                key: key,
                user_name: user_name,
                candidate_id: candidate_id
            }), {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                }
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        },
        async postSuggestion(job_title: any, job_description: any) {
            const objectParams = {
                job_description: job_description,
                job_title: job_title
            }
            return fetch(config.api.base_url + ApiConfig.api.suggestion, {
                method: 'POST', headers: {
                    'x-api-key': config.api.x_api_key,
                }, body: JSON.stringify(objectParams)
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        }, async postExport(candidateIDList: any, jobID: string, user_name: string, source_key ) {

            const objectParams = {
                candidate_ids: candidateIDList,
                push_to_scoutconnect: jobID,
                user_name: user_name,
                source_key: source_key
            }
            return fetch(config.api.base_url + ApiConfig.api.export, {
                method: 'POST', headers: {
                    'x-api-key': config.api.x_api_key,
                    'content-type': 'application/json'
                }, body: JSON.stringify(objectParams)
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        },
        async postExportExternal(candidateIDList: any, jobID: string, user_name: string, source_key ) {

            const objectParams = {
                candidate_ids: candidateIDList,
                push_to_scoutconnect: jobID,
                user_name: user_name,
                source_key: source_key
            }
            return fetch(config.api.base_url + ApiConfig.api.export_request, {
                method: 'POST', headers: {
                    'x-api-key': config.api.x_api_key,
                    'content-type': 'application/json'
                }, body: JSON.stringify(objectParams)
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        },
        async postExportAll(objectResult) {
         
            return fetch(config.api.base_url + ApiConfig.api.search, {
                method: 'POST', headers: {
                    'x-api-key': config.api.x_api_key,
                    'content-type': 'application/json'
                }, body: JSON.stringify(objectResult)
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        }
        , async postSearch(objectResult) {
            return fetch(config.api.base_url + ApiConfig.api.search, {
                method: 'POST', headers: {
                    'x-api-key': config.api.x_api_key,
                    'content-type': 'application/json'
                }, body: JSON.stringify(objectResult)
            }
            ).then(res => res.json()).catch(err => <ErrorResponseModal></ErrorResponseModal>)
        }, async addNote(candidateID, note, username, sourceKey) {
            const objectResultAddNote = {
                note : note,
                user_name: username,
                source_key: sourceKey
            }
            return fetch(config.api.base_url + ApiConfig.api.candidate.path + candidateID + ApiConfig.api.candidate.note, {
                method: 'POST', headers: {
                    'x-api-key': config.api.x_api_key,
                    'content-type': 'application/json'
                }, body: JSON.stringify(objectResultAddNote)
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        }, async checkUsers(email: string) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const docRef = doc(db, "users", email);
            return getDoc(docRef);
        }, async getProfile(email: string) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const docRef = doc(db, "users", email);
            return getDoc(docRef);
        }
        , async createProfile(email, firstName, lastName, loginStatus, uid, version) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const docData = {
                authTime: Timestamp.now(),
                checkUserAppliedJob: true,
                companyName: "SO",
                expiredDate: Timestamp.fromDate(new Date("December 31, 2023")),
                createDate: Timestamp.now(),
                email: email,
                firstName: firstName,
                lastName: lastName,
                loginStatus: loginStatus,
                uid: uid,
                version: version
            };
            return setDoc(doc(db, "users", email), docData);
        }, async updateToken(idToken: any, uid: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
            const docData = {
                idToken: idToken
            };
            return updateDoc(doc(db, "users", uid), docData);
        }, async updateSignIn(email: any, loginStatus: any, uid: any, version: any, authTime: any) {
            const app = initializeApp(config.firebaseConfig);
            const db = getFirestore(app);
          
            const docData = {
                authTime: authTime,
                checkUserAppliedJob: true,
                companyName: "SO",
                expiredDate: Timestamp.fromDate(new Date("December 31, 2023")),
                loginStatus: loginStatus,
                uid: uid,
                version: version
            };
    
        
            return updateDoc(doc(db, "users", email), docData);
        },async activityLog(candidate_id, email) {
           
            return fetch(config.api.base_url + ApiConfig.api.candidate.path + candidate_id + ApiConfig.api.candidate.logs + "?"+  new URLSearchParams({
                username: email
            }),{
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        },async historyJobsLog(candidate_id, username) {
           
            return fetch(config.api.base_url + ApiConfig.api.candidate.path + candidate_id + ApiConfig.api.candidate.experience + "?" +  new URLSearchParams({
                username: username
            }), {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        },  async PKCEencoding(verificationCode: string){
            let result = shajs('sha256').update(verificationCode).digest('base64');
          
            result = result.replaceAll(/\+/g, '-')
            .replaceAll(/\//g, '_')
            .replace(/\=$/, '');
            return result;
        }, randomString(length: number){
            var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var result = '';
            for (var i = 0; i < length; i++) {
              result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
            }
            return result;
        }, async oauthToken(code, codeVerifier, redirect_uri) {
           
            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type' : config.authen.grant_type,
                    'code': code,
                    'code_verifier': codeVerifier,
                    'client_id': config.authen.client_id,
                    'redirect_uri': redirect_uri
                })
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        }
        ,
        async refreshToken(refresh_token) {
           
            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type' : 'refresh_token',
                    'refresh_token' : refresh_token
                })
            }
            ).then(res => res.json()).catch(err => Promise.reject(err))
        }

    }
}