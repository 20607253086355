import axios from "axios";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";
import SearchingAnimation from "../../assets/json/loading-blue.json";
import "./check-login.scss";
import GAConfig from "../../ga-config";
import { getUTMParams, handleNavigation } from "../../services/handler-route.service";
import { SigninContext } from "../../context/signin.context";
import tokenService from "../../services/token.service";
import AuthenMysa from "../../services/authen-mysa";
import GAServices from "../../utils/ga-services";
import MysaService from "../../services/mysa.service";
import { ProfileModel } from "../../model/profile.model";
import config from "../../config";
import ApiConfig from "../../api-config";

const CheckLoginComponent = () => {
    const domainArrayExternal = config.whitelist.external;
    const domainArrayInternal = config.whitelist.internal;
    const { isDomainMatch, setIsDomainMatch }: any = useContext(SigninContext)
    const { versionGlobal, setVersionGlobal }: any = useContext(SigninContext)
    const navigate = useNavigate();
    const { profileModel, creditModel, setProfileModel, setCreditModel }: any = useContext(SigninContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const { utmSource, utmMedium, version, redirect, keyToken } = getUTMParams(location);
    useEffect(() => {

        if (searchParams.get('keyToken')) {
            const token = searchParams.get('keyToken')?.toString();
            tokenService.setIsHr9Clinic(true);
            tokenService.setLocalAccessToken(searchParams.get('keyToken')?.toString());
            getProfile();
        } else if (searchParams.get('code') && searchParams.get('state')) {
            tokenService.setCode(searchParams.get('code') + "");
            checkAuthenMysa(searchParams.get('code') + "");
        } else if (tokenService.getUser()) {
            getProfile();
        }else if(keyToken){
            tokenService.setIsHr9Clinic(true);
            tokenService.setLocalAccessToken(searchParams.get('keyToken')?.toString());
        }
        else {
            handleNavigation(navigate, '/', utmSource, utmMedium, keyToken);
        }


    }, [])


    const checkAuthenMysa = async (code) => {

        const accessTokenResult = await AuthenMysa().getAccessToken(code, tokenService.getVerifyCode(), utmSource, utmMedium, keyToken);
        if (accessTokenResult) {
            tokenService.setUser(accessTokenResult);
            getProfile();
        }
    }

    const getProfile = async () => {
        MysaService.getProfile().then((res) => {
            if (res.data) {
                const profileMod: ProfileModel = res.data;
                let isVersion = false;
                domainArrayExternal.map((item: string, index: number) => {
                    if (profileMod.email.includes(item)) {
                        setVersionGlobal(ApiConfig.version.be_8external);
                        isVersion = true;
                    }
                })
                domainArrayInternal.map((item: string, index: number) => {
                    if (profileMod.email.includes(item)) {
                        setVersionGlobal(ApiConfig.version.internal);
                        isVersion = true;
                    }
                })

                if (!isVersion) {
                    setIsDomainMatch(true)
                    setVersionGlobal(ApiConfig.version.external);
                    if (redirect) {
                        handleNavigation(navigate, redirect, utmSource, utmMedium, keyToken);
                    } else {
                        handleNavigation(navigate, '/search', utmSource, utmMedium, keyToken);
                    }

                } else {
                    setIsDomainMatch(true)
                    if (redirect) {
                        handleNavigation(navigate, redirect, utmSource, utmMedium, keyToken);
                    } else {
                        handleNavigation(navigate, '/search', utmSource, utmMedium, keyToken);
                    }
                }

            }
        }).catch((err) => {
            navigate('/')
        })

    }
    const RegisterProfile = () => {
        AuthenMysa().registerProfile().then((res) => {
            if (res) {
                getProfile();
            } else {
                localStorage.clear();
                handleNavigation(navigate, '/', utmSource, utmMedium, keyToken);
            }
        })
    }




    return (

        <>
            <div className="check-login">
                <div className="check-login-control">
                    <Lottie animationData={SearchingAnimation} loop={true} className="check-login-loading" />
                    <span className="check-login-text">Loading . . .</span>
                </div>
            </div>
        </>
    )
}

export default CheckLoginComponent;